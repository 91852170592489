import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {useSelector, useDispatch} from 'react-redux'
import dummyImg from '../../../../static/images/avatar/1.jpg';
import InlineLoader from '../../../helpers/InlineLoader';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { downloadURI, formatUrl, downloadFileFromUrl, downloadFunction } from '../../../utils/urls';
import moment from "moment"
import BtnAddToFavourities from '../../../helpers/BtnAddToFavourities';
import {OverlayTrigger, Tooltip} from "react-bootstrap"
import ModalUpdateStatus from '../../../helpers/Files/ModalUpdateStatus';
import NameComponent from '../../../helpers/Files/NameComponent';
import ModalShowFile from '../../../helpers/ShowFile';
import ModalRenameFile from '../../../helpers/RenameFile';
import ModalDeleteFile from '../../../helpers/DeleteFile';
import ModalShareFile from '../../../helpers/ModalShareFile';
import BtnAddToSymbolicLink from '../../../helpers/BtnAddToSymbolicLink';
import DataGroupButtons from '../../../helpers/DataGroupButtons';


function SymbolicLinks() {

    let url = "/filemanager/symbolic-link/";
    const session = useSelector(state => state.session);
    const userPermissions = session.userData.permissions;

    const [loading, setloading]   = useState(true);
    const [search,  setsearch]    = useState(true);
    const [data,    setdata]      = useState(null);

    const [folderSelected, setfolderSelected]       = useState(null);
    const [fileSelected,   setfileSelected]       = useState(null);

    const [showModalStatus, setShowModalStatus] = useState(false);
    const [count, setcount] = useState(0);

    const dispatch        = useDispatch();
    const downloadList    = useSelector(state => state.dashboard.download_list);
    console.log("Lista de descargas:",downloadList);

    const getData = () => {
      axios.get(url).then((res) => {
        console.log("Data",res.data);
        setdata(res.data);
        setloading(false);
      }).catch((err) => {
        console.error(err);
      });
    }
    
    useEffect(() => {
      if(loading){
        if(search){
          setsearch(false);
          getData();
        }
      }
    }, []);

    //show
    const [showModalShowFile, setShowModalShowFile] = useState(false);
    const handleCloseModalShowFile = () => {
      setShowModalShowFile(false);
    }
    const handleShowModalShowFile = () => {
      setShowModalShowFile(true)
    };

    const showFileNow = (file) => {
      setfileSelected(file);
      handleShowModalShowFile();
    }

    //rename
    const [showModalRenameFile, setShowModalRenameFile] = useState(false);
    const handleCloseModalRenameFile = () => {
      setShowModalRenameFile(false);
    }
    const handleShowModalRenameFile = () => {
      setShowModalRenameFile(true)
    };

    const RenameFileNow = (file) => {
      setfileSelected(file);
      handleShowModalRenameFile();
    }

    //share
    const [showModalShareFile, setShowModalShareFile] = useState(false);
    const handleCloseModalShareFile = () => {
      setShowModalShareFile(false);
    }
    const handleShowModalShareFile = () => {
      setShowModalShareFile(true)
    };

    const shareFileNow = (file) => {
      setfileSelected(file);
      handleShowModalShareFile();
    }

    //delete
    const [showModalDeleteFile, setShowModalDeleteFile] = useState(false);
    const handleCloseModalDeleteFile = () => {
      setShowModalDeleteFile(false);
    }
    const handleShowModalDeleteFile = () => {
      setShowModalDeleteFile(true)
    };

    const deleteFileNow = (file) => {
      setfileSelected(file);
      handleShowModalDeleteFile();
    }

    const refresh = () => {
      getData();
    }

    const deleteFileBYid = async (id) => {
      let listWithoutFile = data.filter(item => item.file.id !== id);
      console.log(id);
      console.log(listWithoutFile);

      await setdata([]);
      setdata(listWithoutFile);
      setcount(count + 5 * 25);
    }

    const showModal = (item, typeModal, typeItem) => {

      if(typeItem === "folder"){
        setfolderSelected(item);

        switch (typeModal) {
          case "rename":
            //setShowModalRenameFolder(true);
            break;

          case "move":
            //setShowModalMoveFile(true);
            break;

          case "share":
            //setShowModalShareFolder(true);
            break;

          case "delete":
            //setShowModalDeleteFolder(true);
            break;
        
          default:
            break;
        }
      }else if(typeItem === "file"){
        setfileSelected(item);

        switch (typeModal) {
          case "rename":
            setShowModalRenameFile(true);
            break;

          case "move":
            //setShowModalMoveFile(true);
            break;

          case "share":
            setShowModalShareFile(true);
            break;

          case "delete":
            setShowModalDeleteFile(true);
            break;

          case "download":
            downloadFileFromUrl(null, formatUrl(item.file), item.name, downloadFunction, item, dispatch, downloadList)
            break;

          case "show":
            setShowModalShowFile(true);
            break;

          case "editStatus":
            setShowModalStatus(true);
          break;

          case "editCategory":
            //setShowModalEditCategory(true);
          break;
        
          default:
            break;
        }
      }
    }

    return (
        <div className="">
        <div className="row mb-5 align-items-center">
          <div className="col-lg-12">
            <div className="card m-0 ">
              <div className="card-body py-3 py-md-2">
                <div className="row align-items-center">
                  <div className="col-md-5 mb-md-0">
                    
                    <h3 className="mb-0 text-black font-w600 fs-20">Accesos directos</h3>
                      
                  </div>
                </div>							
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-xl-12">	
                {(!loading && data !== null) 
                ?
                  <div>
                    <div class="table-responsive">
                          <table className="table display dataTablesCard fs-14 dataTable no-footer" id="example5" role="grid" aria-describedby="example5_info">
                            <thead>
                              <tr role="row">
                                <th style={{width: '250px'}} className="sorting_asc" tabIndex={0} aria-controls="example5" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Archivo: activate to sort column descending">Archivo</th>
                                <th className="d-none text-center d-lg-table-cell sorting" tabIndex={0} aria-controls="example5" rowSpan={1} colSpan={1} aria-label="Categoría: activate to sort column ascending" style={{width: '116px'}}>Categoría</th>
                                <th className="text-center sorting" tabIndex={0} aria-controls="example5" rowSpan={1} colSpan={1} aria-label="Acción: activate to sort column ascending" style={{width: '351px'}}>Acción</th>
                              </tr>
                            </thead>
                            {(Array.isArray(data) && data.length > 0) ?
                            <tbody>
                              {data.map((item, key) => {

                                let filename = item.file.name.split('.');
                                let formattext = filename[filename.length - 1].toLowerCase();

                                return (
                                  <tr key={key} role="row" className="odd">
                                    <td className="sorting_1">
                                      <div className="media align-items-center">
                                        {(item.file.thumbnail !== null && item.file.thumbnail !== '') 
                                        ?
                                          <img 
                                            className="img-fluid rounded mr-3 d-xl-inline-block" 
                                            width={70} 
                                            src={formatUrl(item.file.thumbnail)} 
                                            alt="Carpeta" 
                                          />
                                        :
                                          <div style={{ width: "70px", marginRight: "15px" }}>
                                            <div style={{ width: "70px"}}></div>
                                            <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                          </div>
                                        }
                                        <div className="media-body">
                                            <NameComponent
                                              item={item.file} 
                                              id={item.file.id} 
                                              name={item.file.name} 
                                              status={item.file.status} 
                                              status_name={item.file.status_name} 
                                              selectItem={setfileSelected}
                                              showModalStatus={() => setShowModalStatus(true)}
                                            />
                                        </div>
                                      </div>
                                    </td>
                                    <td className="d-none d-lg-table-cell text-center">
                                      {item.file.category !== null ? item.file.category.breadcrumb : "--"}
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-start">
                                          <DataGroupButtons
                                            page="simbolic-links"
                                            type="file"
                                            userPermissions={userPermissions} 
                                            id={`btn-file-simbolic-links-${item.id}`} 
                                            item={item.file}
                                            permissions={item.permissions}
                                            setFolder={setfolderSelected}
                                            setFile={setfileSelected}
                                            showModal={showModal}
                                            updateSymbolicBtn={deleteFileBYid}
                                          />                                        
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                            :
                            <tbody>
                              <tr className="odd">
                                <td className="text-center" colSpan="4">
                                  <i className="fa fa-exclamation-triangle mr-2"></i>Sin resultados encontrados
                                </td>
                              </tr>
                            </tbody>
                            }
                          </table>
                    </div>
                  </div>
                :
                  <InlineLoader />
                }
          </div>
        </div>

        {userPermissions.can_share_files &&
          <ModalShareFile  refresh={refresh}    file={fileSelected}     show={showModalShareFile}       close={handleCloseModalShareFile} />
        }
        <ModalRenameFile   refresh={refresh}    file={fileSelected}     show={showModalRenameFile}      close={handleCloseModalRenameFile} />
        <ModalDeleteFile   refresh={refresh}    file={fileSelected}     show={showModalDeleteFile}      close={handleCloseModalDeleteFile} />
        <ModalShowFile     refresh={refresh}    file={fileSelected}     show={showModalShowFile}        close={handleCloseModalShowFile} />
        <ModalUpdateStatus refresh={refresh}    file={fileSelected}     show={showModalStatus}          close={() => setShowModalStatus(false)} />
      </div>
    )
}

export default SymbolicLinks
