//modules
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'

import Dashboard from './dashboard/Dashboard';
import Public from './public/Public';

//import FullLoader from '../helpers/FullLoader';

import axios from 'axios'
import Loader from '../helpers/Loader';
import { set_categories, set_status, set_symbolic_links, set_tags } from '../../store/dashboard/actions';
import { handleLogin, handleLogout, set_settings } from '../../store/session/actions';
import DownloadFilesList from '../helpers/DownloadFilesList';

// Configuración inicial del router y peticiones HTTP:

switch (window.location.origin) {
  case 'https://mobilperurepositorio.com':  // Producción
    axios.defaults.baseURL = 'https://backend.mobilperurepositorio.com';
    break;
  case 'http://mobilrepofront.rlsoluciones.com':  // Test para el cliente
    axios.defaults.baseURL = 'http://mobilrepoback.rlsoluciones.com';
    break;
  case 'http://127.0.0.1:3001':  // Test con backend local para el equipo de Desarrollo
    axios.defaults.baseURL = 'http://127.0.0.1:8000';
    break;
  case 'http://mobilrepo.rlsoluciones.com':  // Test para el equipo de Desarrollo
  case 'http://127.0.0.1:3000':  // Desarrollo de frontend
  default:
    axios.defaults.baseURL = 'https://mobilrepoapitest.rlsoluciones.com';
}

function AppRouter() {              
  const dashboard         = useSelector(state => state.dashboard);
  const session           = useSelector(state => state.session);

  const auth = session.auth;

  const [loged, setloged] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  const [loading, setloading] = useState(true);
  const [loaded, setloaded] = useState(true);

  const dispatch = useDispatch();

  //console.log(auth);

  const login =  async (token) => {
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    let gotoLogin = await dispatch(handleLogin(token));
    console.log(gotoLogin);
  }

  let failRequestByToken = false;

  useEffect(() => {
    let token = localStorage.getItem('token');

    if(token){
      //INTERCEPCION
      axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {

        if (!error.response) {
            //alert('NETWORK ERROR, revise su conexion a internet')
        } else {
            const code = error.response.status
            const originalRequest = error.config;

            if (code === 401 && !originalRequest._retry && !failRequestByToken) {
                originalRequest._retry = true;
                failRequestByToken = true;
                
                if(!auth){
                  console.log('El token ha caducado, logout');
                  dispatch(handleLogout());
                  setloaded(false);
                  setLoadingData(true);
                }else{
                  console.log('El token ha caducado, logout');
                }
            }
            
            return Promise.reject(error);
        }
      });
    }
    
    if(loaded){
      if(loading){
        setloading(false);
        if(token){
          login(token);
        }else{
          setloaded(false);
          setLoadingData(true);
        }
      }else{
        if(token && auth){
          if(loadingData){
            setLoadingData(false);
            //cargando maestros y datos de la app
            if(dashboard.categories === null){
              dispatch(set_categories());
            }

            if(dashboard.tags === null){
              dispatch(set_tags());
            }

            if(dashboard.status === null){
              dispatch(set_status());
            }

            if(session.settings === null){
              dispatch(set_settings());
            }

            /*
            console.log(session.userData);
            if(typeof session.userData === "object" && session.userData.hasOwnProperty("permissions")){
              console.log("accesos directos activados:"+session.userData.permissions.has_symbolic_links_access);
              if(!session.userData.permissions.has_symbolic_links_access){
                dispatch(set_symbolic_links());
              }
            }
            */
           
          }else{
            //cargando app en router privado
            if(session.userData !== null && dashboard.categories !== null && dashboard.tags !== null && dashboard.status !== null){
              setloaded(false);
            }
          }
        }
      }
    }else{
      if(auth){
        if(loadingData){
          setLoadingData(false);
          //cargando maestros y datos de la app
          if(dashboard.categories === null){
            dispatch(set_categories());
          }

          if(dashboard.tags === null){
            dispatch(set_tags());
          }

          if(dashboard.status === null){
            dispatch(set_status());
          }

          if(session.settings === null){
            dispatch(set_settings());
          }

          /*
          console.log(session.userData);
          if(typeof session.userData === "object" && session.userData.hasOwnProperty("permissions")){
            console.log("accesos directos activados:"+session.userData.permissions.has_symbolic_links_access);
            if(!session.userData.permissions.has_symbolic_links_access){
              dispatch(set_symbolic_links());
            }
          }
          */

        }else{
          //cargando app en router privado
          if(session.userData !== null && session.settings !== null && dashboard.categories !== null && dashboard.tags !== null && dashboard.status !== null){
            setloaded(false);
          }
        }
      }else{
        setLoadingData(true);
      }
    }
  });

  if(!loaded){
    if(auth){
      //console.log('Private router');
  
      let token = localStorage.getItem('token');
      if(token){
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
      }
      
      if(session.userData !== null && dashboard.categories !== null && session.settings !== null){
        return (
          <div className="app-container">
              <Dashboard />
              <DownloadFilesList />
          </div>
        );
      }else{
        return (
          <div className="app-container">
              <Loader />
          </div>
        );
      }
    }else{

      delete axios.defaults.headers.common["Authorization"];
      //console.log('Public router');
      return (
        <div className="app-container">
            <Public />
            <DownloadFilesList />
        </div>
      );
    }
  }else{
    return (
      <div className="app-container">
          <Loader />
      </div>
    );
  }
}

export default AppRouter;