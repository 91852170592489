import React, {useState, useEffect} from 'react'
import axios from "axios"

import { FileIcon, defaultStyles } from 'react-file-icon';
import {OverlayTrigger, Tooltip} from "react-bootstrap"
import {withRouter, Link} from 'react-router-dom'
import Loader from '../../../helpers/Loader';
import ModalShowFile from '../../../helpers/ShowFile';

import BtnAddToFavourities from '../../../helpers/BtnAddToFavourities';
import ModalUpdateStatus from '../../../helpers/Files/ModalUpdateStatus';
import NameComponent from '../../../helpers/Files/NameComponent';

import {useSelector, useDispatch} from 'react-redux'
import { downloadURI, formatUrl, downloadFileFromUrl, downloadFunction } from '../../../utils/urls';
import DataGroupButtons from '../../../helpers/DataGroupButtons';

function FilePreview(props) {

    let id = props.match.params.id;

    const [actualId,    setactualId]    = useState(id !== null ? id : null);
    const [loading,     setloading]     = useState(true);
    const [search,      setsearch]      = useState(true);
    const [data,        setdata]        = useState(null);

    const [fileSelected,   setfileSelected]       = useState(null);

    const [showModalStatus, setShowModalStatus] = useState(false);
    const [showModalEditCategory, setShowModalEditCategory] = useState(false);

    const dispatch        = useDispatch();
    const downloadList    = useSelector(state => state.dashboard.download_list);
    console.log("Lista de descargas:",downloadList);

    //show
    const [showModalShowFile, setShowModalShowFile] = useState(false);
    const handleCloseModalShowFile = () => {
      setShowModalShowFile(false);
    }
    const handleShowModalShowFile = () => {
      setShowModalShowFile(true)
    };

    const showFileNow = (file) => {
        setfileSelected(file);
        handleShowModalShowFile();
    }

    const downloadFile = (e, fileurl, filename) => {
        e.preventDefault();
        //console.log(fileurl);
        //console.log(filename);
        
        const linkSource      = fileurl;
        const fileName        = filename;
        const downloadLink    = document.createElement("a");
  
        downloadLink.href     = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const getData = () => {
        let url = "/filemanager/files/"+actualId+"/";
        axios.get(url).then((res) => { 
            console.log(res.data);
            setdata(res.data);
            setloading(false);
        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        if(loading){
            if(search){
                setsearch(false);
                getData();
            }
        }else{
            if(id !== actualId){
                setactualId(id);
                setsearch(true);
                setloading(true);
            }
        }
    });

    const refresh = () => {
        getData();
    }

    const showModal = (item, typeModal, typeItem) => {

        if(typeItem === "folder"){
          //setfolderSelected(item);
  
          switch (typeModal) {
            case "rename":
              //setShowModalRenameFolder(true);
              break;
  
            case "move":
              //setShowModalMoveFile(true);
              break;
  
            case "share":
              //setShowModalShareFolder(true);
              break;
  
            case "delete":
              //setShowModalDeleteFolder(true);
              break;
          
            default:
              break;
          }
        }else if(typeItem === "file"){
          setfileSelected(item);
  
          switch (typeModal) {
            case "rename":
              //setShowModalRenameFile(true);
              break;
  
            case "move":
              //setShowModalMoveFile(true);
              break;
  
            case "share":
              //setShowModalShareFile(true);
              break;
  
            case "delete":
              //setShowModalDeleteFile(true);
              break;
  
            case "download":
              downloadFileFromUrl(null, formatUrl(item.file), item.name, downloadFunction, item, dispatch, downloadList)
              break;
  
            case "show":
              setShowModalShowFile(true);
              break;
  
            case "editStatus":
              setShowModalStatus(true);
            break;
  
            case "editCategory":
              setShowModalEditCategory(true);
            break;
          
            default:
              break;
          }
        }
    }

    if(!loading){

        let item = data;
        let filename = item.name.split('.');
        //let categories = item.category.breadcrumb.split(">");
        let formattext = filename[filename.length - 1].toLowerCase();

        return (
            <div>
                {(item.is_public && item.category !== null) &&
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={"/categoria/"+item.category.id}>
                                    {item.category.breadcrumb}
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <a href="##">
                                    {id}
                                </a>
                            </li>
                        </ol>
                    </div>
                }
                <div className="card">
                    <div className="card-body p-4">
                        {(item.is_public) ? 
                            <span className="badge shadow small badge-info font-weight-bold px-3">Público</span> : 
                            <span className="badge shadow small badge-primary font-weight-bold px-3">Privado</span>
                        }
                        {(item.folder !== null) &&
                            <div>
                                <p>
                                    <i className="fa fa-folder mr-2"></i> {item.folder.name}
                                </p>
                            </div>
                        }
                        <div className="header-preview mb-3">
                            <div className="row align-items-end">
                                <div className="col-auto">
                                    <div style={{ width: "50px", marginRight: "20px" }}>
                                        <div style={{ width: "50px"}}></div>
                                        {(item.thumbnail !== null && item.thumbnail !== '') 
                                            ?
                                              <img 
                                                className="img-fluid rounded mr-3 d-xl-inline-block" 
                                                width={70} 
                                                src={formatUrl(item.thumbnail)} 
                                                alt="Carpeta" 
                                              />
                                            :
                                            <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                        }   
                                    </div>
                                </div>
                                <div className="col">
                                    <h6>Nombre:</h6>
                                    <div className="d-flex align-items-end">
                                        <NameComponent
                                            titleclass="h3 text-dark mb-0 font-weight-bold"
                                            item={item} 
                                            id={item.id} 
                                            name={item.name} 
                                            status={item.status} 
                                            status_name={item.status_name} 
                                            selectItem={setfileSelected}
                                            showModalStatus={() => setShowModalStatus(true)}
                                            //showBtn={item.permissions.can_change_status}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-auto">
                                    <h6>Creador:</h6>
                                    <p className="mb-0">
                                        {item.owner.name} - (<i className="fa fa-envelope mr-2"></i>{item.owner.email})
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <h6>Url:</h6>
                        <p className="mb-3 text-primary">
                            {formatUrl(item.file)}
                        </p>
                        <div className="d-flex align-items-start">
                            {item.permissions.can_change_status &&
                                <ModalUpdateStatus refresh={refresh}  type="status"  file={fileSelected} show={showModalStatus} close={() => setShowModalStatus(false)} />
                            }

                            {item.permissions.can_change_category &&
                                <ModalUpdateStatus refresh={refresh}  type="category"  file={fileSelected} show={showModalEditCategory} close={() => setShowModalEditCategory(false)} />  
                            }                

                            
                            <DataGroupButtons 
                                page="preview"
                                type="file"
                                align="start"
                                //userPermissions={userPermissions} 
                                id={`btn-file-preview-${item.id}`} 
                                item={item}
                                permissions={item.permissions}
                                showModal={showModal}
                            /> 
                                
                        </div>
                        {/*
                            <button onClick={(e) => downloadFile(e, formatUrl(item.file), item.name)}
                            className="btn btn-info btn_off btn-sm ml-2 py-3 px-4">
                                <i className="fa fa-download d-block mb-2"/> Descargar
                            </button>
                        */}
                    </div>
                </div>

                <ModalShowFile     refresh={refresh} file={fileSelected}    show={showModalShowFile}     close={handleCloseModalShowFile} />
            </div>
        )
    }else{
        return (
            <div>
                <Loader />
            </div>
        )
    }
}

export default withRouter(FilePreview);
