import React, {useState} from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon';
import {Link} from "react-router-dom"
import axios from 'axios'
import { formatUrl } from '../utils/urls';
import SelectCategories from './SelectCategories'
import InlineLoader from './InlineLoader';

function CardSearch() {

    const [textsearch,  settextsearch]  = useState('');
    const [searching,   setsearching]   = useState(false);
    const [data,        setdata]        = useState(null);
    const [category,    setcategory]    = useState(null);
    const [errors,      seterrors]      = useState({});

    const changecat = (value) => {
        setcategory(value);
        let transformText = encodeURI(textsearch.trim().replace(/\s+/gi,' '));
        let textValues    = transformText.split('%20');
        let textResult    = textValues.join('+');

        if(textResult !== ''){
            searchData();
        }
    }

    const searchData = () => {
        let transformText = encodeURI(textsearch.trim().replace(/\s+/gi,' '));
        let textValues    = transformText.split('%20');
        let textResult    = textValues.join('+');
        let urlpublic     = '';

        seterrors({});

        if(category === null || (typeof category === 'object' && category.value === 0)){
            urlpublic = "/filemanager/search-public-files/?q="+textResult;
        }else{
            urlpublic = `/filemanager/search-public-files-by-category/${category.value}/?q=${textResult}`;
        }

        setsearching(true);

        axios.get(urlpublic).then((res) => {
            console.log(res.data);
            setdata(res.data);
            setsearching(false);
        }).catch((err) => {
            console.error(err);
            setsearching(false);

            let response = err.response;

            if(response.hasOwnProperty("data")){
                seterrors(response.data);
            }
        });
    }

    const search = (e) => {
        e.preventDefault();
        e.stopPropagation();

        searchData();
    }

    const changetext = (value) => {
        if(value === ''){
            seterrors({});
            settextsearch(value);
            setsearching(false);
            setdata(null);
        }else{
            seterrors({});
            settextsearch(value);
        }
    }

    const isBlur = () => {
        setTimeout(() => {
            setdata(null);
        }, 200);
    }

    return (
        <div className="col-xl-12"> 
            <div className="row"> 
                <div className="col-xl-12">
                    <div className="card h-auto p-4">
                        <h4 className="card-title h6 mb-3 col-xl-12">
                            Búsqueda en Categorías:
                        </h4>
                        <form onSubmit={(e) => search(e)} action="">
                            <div className="form-group position-relative">
                                <div className="input-group mb-3">
                                    <input 
                                        value={textsearch}
                                        onChange={(e) => changetext(e.target.value)}
                                        onBlur={() => isBlur()}
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Búsqueda en Categorías" 
                                    />
                                    <div className="input-group-append">
                                        <button 
                                            disabled={searching} 
                                            type="submit" 
                                            className="btn btn-primary" 
                                        >
                                            {(!searching) ? <span>Buscar</span> : <i className="fa fa-spin fa-spinner"></i>}
                                        </button>
                                    </div>
                                </div>
                                {(errors.hasOwnProperty("detail")) &&
                                    <p className="text-danger small font-weight-bold">
                                        <i className="fa fa-exclamation mr-2"></i>{errors.detail}
                                    </p>
                                }
                                {((data !== null && Array.isArray(data)) || searching) &&
                                <div className="search-results-container">
                                    {!searching &&
                                        <div className="card shadow p-4">
                                            {(data.length > 0) &&
                                                <ul className="search-results-container-data">
                                                    {data.map((item, key) => {
                                                        let filename = item.name.split('.');
                                                        let formattext = filename[filename.length - 1].toLowerCase();
                                                        
                                                        return (
                                                            <li className="d-flex link-item-preview-list align-items-center" key={key}>
                                                                <Link 
                                                                    className="d-flex w-100 py-3 align-items-center" 
                                                                    to={"/file/preview/"+item.id+"/"}
                                                                >
                                                                    {(item.thumbnail !== null && item.thumbnail !== '') 
                                                                        ?
                                                                        <img 
                                                                            style={{marginRight: "30px"}}
                                                                            className="img-fluid rounded d-xl-inline-block" 
                                                                            width={40} 
                                                                            src={formatUrl(item.thumbnail)} 
                                                                            alt="img-thumb" 
                                                                        />
                                                                        :
                                                                        <div style={{ width: "40px", marginRight: "30px" }}>
                                                                            <div style={{ width: "40px"}}></div>
                                                                            <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                                                        </div>
                                                                    }
                                                                    <h6 className="mb-0 h5 d-inline-flex text-dark">
                                                                        {filename}
                                                                    </h6>
                                                                </Link>
                                                            </li>
                                                        )

                                                    })}
                                                </ul>
                                            }

                                            {(data.length === 0) &&
                                                <p className="text-center mb-0">
                                                    <i className="fa fa-exclamation-triangle mr-2"></i>
                                                    Sin resultados
                                                </p>
                                            }
                                        </div>
                                    }

                                    {searching &&
                                        <div className="card p-4">  
                                            <InlineLoader />
                                            <p className="mb-0 d-none text-center">
                                                    <i className="fa fa-spin fa-spinner fa-1x text-muted"></i>
                                            </p>
                                        </div>    
                                    }
                                </div>
                            }
                            </div>
                            <div className="form-group">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <label htmlFor="">Categorías</label>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-3">
                                        <SelectCategories value={category} onChange={(value) => changecat(value)} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="small mb-1">
                                    Para buscar archivos, digite un texto y a continuación haga clic en el botón Buscar o presione la tecla Enter. La búsqueda se realiza sobre los nombres de archivo. Solo se consideran los archivos públicos. Se puede afinar la búsqueda seleccionando una categoría específica.
                                </p>
                                <p className="small">
                                    <strong>Por ejemplo:</strong> Si buscara el texto "ami", se listarán todos los archivos públicos, de la categoría seleccionada, cuyos nombres contienen el texto "ami", como: camión, amigo, camino, etc.
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardSearch
