import React, { useState, useRef } from 'react'
import {useSelector} from 'react-redux'
//import 'react-bootstrap-tagsinput/dist/index.css'
// react-fetch-progressbar

function TagsInput(props) {

    let idinput = props.idinput ? props.idinput : 'inputtag';
    let valuetaglist = props.value !== null ? props.value : [];

    const [taglist, settaglist] = useState([]);
    const [textinput, settextinput] = useState('');
    const [errormessage, seterrormessage] = useState('')

    const dashboard = useSelector(state => state.dashboard);
    let tags = dashboard.tags;

    const updateTagList = (list) => {
        if(list.length === 0){
            props.onChange(null);
        }else{
            props.onChange(list);
        }

        console.log(list);
        settaglist(list);
    }

    const addTag = (name) => {
            let newTagList = taglist === null ? [] : taglist;
            let filters     = [];
            let tagfilters  = [];
            if(textinput !== ''){
                tagfilters  = tags.filter(tag => tag.name.trim().toLowerCase() === name.toLowerCase());
                filters     = taglist.filter(tag => tag.name.trim().toLowerCase() === name.toLowerCase());

                //console.log(tagfilters);
                //console.log(filters);

                if(tagfilters.length > 0 && (filters === null || filters.length === 0)){
                    taglist.push(tagfilters[0]);
                    settextinput('');
                    seterrormessage('');
                }else{
                    if(tagfilters.length === 0){
                        seterrormessage('Debe seleccionar una etiqueta valida')
                    }else{
                        seterrormessage('No puede ingresar dos etiquetas iguales');
                    }
                }

                //console.log(taglist);
                updateTagList(newTagList);
            }
    }

    const deleteTag = (id) => {
        let list = taglist;
        let newlist = list.filter((item) => item.id !== id);

        console.log(newlist);
        updateTagList(newlist);
    }

    let filters = [];
    if(textinput !== ''){
        filters = tags.filter(tag => tag.name.trim().toLowerCase().includes(textinput.trim().toLowerCase()));
    }

    const input = useRef(null);

    //console.log(filters);
    //console.log(tags);

    const focusInput = () => {
        console.log(input);
        input.current.focus();
    }

    return (
        <div className="taginput-container">
            {(errormessage !== '') && 
                <div className="alert mb-2 py-2 alert-info">
                    <p className="small font-weight-bold mb-0">{errormessage}</p>
                </div>
            }
            <div className='container-list' onClick={() => focusInput()}>
                <div className="input-tag-content d-flex flex-wrap">
                    {(valuetaglist.length > 0 && valuetaglist.map((item, key) => {
                        return (
                            <div 
                            key={key} 
                            className="badge my-1 mr-1 badge-primary font-weight-normal d-inline-flex justify-content-between align-items-center">
                                {item.name} 
                                <button 
                                onClick={() => deleteTag(item.id)} 
                                className="btn-unstyled ml-3">
                                    <i className="fa text-white fa-times"></i>
                                </button>
                            </div>
                        )
                    }))}
                    <input 
                        ref={input}
                        value={textinput} 
                        //onKeyUp={(e) => handlekeyup(e)} 
                        onChange={(e) => settextinput(e.target.value)} 
                        className="border-none input-tag-control w-auto" 
                        id={idinput} type="text" 
                        placeholder="Escriba su etiqueta" 
                        autoComplete="off"
                    />
                </div>
            </div>
            {tags.length > 0 && filters.length > 0 && filters !== null && textinput.length >= 1 &&
                <div className="tag-content-result">
                    <div className="card py-0 mb-0">
                        <div className="card-body p-0 mb-0">
                            <ol className="m-0 p-0">
                                {filters.map((item, index) => {
                                    let nameInList = valuetaglist.find(tag => tag.name.toLowerCase() === item.name.toLowerCase());

                                    if(nameInList === undefined || nameInList === null){
                                        return (
                                            <li onClick={() => addTag(item.name)} key={index} className="px-4 py-2 small">
                                                {item.name} 
                                                {(item.name.trim().toLowerCase() === textinput.trim().toLowerCase()) &&
                                                    <span className="d-none">
                                                        <i className="fa fa-check-circle ml-4 text-success mr-2"></i> <strong>Presione enter para añadir</strong>
                                                    </span>
                                                }
                                            </li>
                                        )
                                    }else{
                                        return (
                                            <li onClick={() => addTag()} key={index} className="small px-2 py-3 d-none">
                                                {item.name} 
                                                {(item.name.trim().toLowerCase() === textinput.trim().toLowerCase()) &&
                                                    <span>
                                                        <i className="fa fa-check-circle ml-4 text-success mr-2"></i> <strong>Presione enter para añadir</strong>
                                                    </span>
                                                }
                                            </li>
                                        )
                                    }
                                })}
                            </ol>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default TagsInput
