import {RESET_DASHBOARD, SET_CATEGORIES, SET_STATUS, SET_TAGS, SET_SYMBOLIC_LINKS, SET_DOWNLOAD_LIST} from '../types'
import axios from 'axios';

export const set_download_list = (data) => {
    return dispatch => {
        dispatch({
            type: SET_DOWNLOAD_LIST,
            payload: data
        });
    }
}

export const set_categories = () => {
    //let url = '/filemanager/all-categories/';
    let url = `/filemanager/all-active-categories/`;
    
    return dispatch => {
        axios
          .get(url)
          .then(res => {
            if(res.data){
                console.log(res.data);
                dispatch({
                    type: SET_CATEGORIES,
                    payload: res.data
                })
            }
          })
          .catch(err => console.log(err + "action"))
    }
}

export const set_symbolic_links = () => {
    //let url = '/filemanager/all-categories/';
    //let url = `/filemanager/all-active-categories/`;
    console.log("buscando accesos directos");

    /*
        return dispatch => {
            axios
            .get(url)
            .then(res => {
                if(res.data){
                    console.log(res.data);
                    dispatch({
                        type: SET_SYMBOLIC_LINKS,
                        payload: res.data
                    })
                }
            })
            .catch(err => console.log(err + "action"))
        }
    */
}

export const set_tags = () => {
    let url = '/filemanager/all-tags/';

    return dispatch => {
        axios
          .get(url)
          .then(res => {
            if(res.data){
                console.log(res.data);
                dispatch({
                    type: SET_TAGS,
                    payload: res.data
                })
            }
          })
          .catch(err => console.log(err + "action"))
    }
}

export const set_status = () => {
    let url = '/filemanager/list-file-status/';

    return dispatch => {
        axios
          .get(url)
          .then(res => {
            if(res.data){
                console.log(res.data);
                dispatch({
                    type: SET_STATUS,
                    payload: res.data
                })
            }
          })
          .catch(err => console.log(err + "action"))
    }
}

export const reset_dashboard = () => {
    return dispatch => {
        dispatch({
            type: RESET_DASHBOARD,
            payload: null
        })
    }
}