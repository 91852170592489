import React, {useState} from 'react'
import axios from 'axios'
import {useSelector, useDispatch} from 'react-redux'
import { change_user_data, set_user_data } from '../../store/session/actions';

function AccountSettings(props) {
    let data = props.data;
    const dispatch = useDispatch();

    const [sending, setsending]                 = useState(false);
    const [errors, seterrors]                   = useState({});
    const [successmessage, setsuccessmessage]   = useState('');

    const [name, setname]               = useState(data.first_name);
    const [lastname, setlastname]       = useState(data.last_name);
    const [email, setemail]             = useState(data.email);
    const [address, setaddress]         = useState(data.address);
    const [phone, setphone]             = useState(data.phone);

    let urlUpdate = '/accounts/update-profile/';

    const validate = () => {
        return true;
    }

    const updateProfile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        seterrors({});
        setsuccessmessage('');

        let isValid = validate();

        if(isValid){

            setsending(true);

            axios({
                method: 'PUT',
                url: urlUpdate,
                data: {
                    first_name: name,
                    last_name: lastname,
                    email,
                    address,
                    phone
                }
            }).then((res) => {
                console.log(res.data);
                dispatch(change_user_data(res.data));
                setsending(false);
                setsuccessmessage('Datos actualizados correctamente');

                setTimeout(() => {
                    setsuccessmessage('');
                }, 3000);
            }).catch((err) => {
                console.log(err.response);

                let res = err.response;
                if(typeof res === 'object' && res.hasOwnProperty('data')){
                    seterrors(err.response.data);
                }

                setsending(false);
            })
        }else{

        }
    }

    return (
        <div className="settings-form">
            {(successmessage !== '') &&
                <div className="alert alert-success">
                    <p className="mb-0 font-weight-normal">{successmessage}</p>
                </div>
            }
            <h4 className="text-primary">Configuración de la cuenta</h4>
            <form onSubmit={(e) => updateProfile(e)} >
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="name">Nombre</label>
                        <input 
                            id="name" 
                            type="text" 
                            value={name} 
                            onChange={(e) => setname(e.target.value)} 
                            placeholder="Nombre" 
                            className={(errors.hasOwnProperty('name') ? 'form-control is-invalid' : 'form-control')}  
                        />
                        {errors.hasOwnProperty('lastname') &&
                            <div className="text-danger font-weight-normal small py-2">
                                {errors.lastname}
                            </div>
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="lastname">Apellido</label>
                        <input 
                            id="lastname" 
                            type="text" 
                            value={lastname} 
                            onChange={(e) => setlastname(e.target.value)} 
                            placeholder="Apellido" 
                            className={(errors.hasOwnProperty('lastname') ? 'form-control is-invalid' : 'form-control')}  
                        />
                        {errors.hasOwnProperty('lastname') &&
                            <div className="text-danger font-weight-normal small py-2">
                                {errors.lastname}
                            </div>
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="email">Email</label>
                        <input 
                            id="email" 
                            type="email" 
                            value={email} 
                            onChange={(e) => setemail(e.target.value)} 
                            placeholder="Correo electrónico" 
                            className={(errors.hasOwnProperty('email') ? 'form-control is-invalid' : 'form-control')} 
                        />
                        {errors.hasOwnProperty('email') &&
                            <div className="text-danger font-weight-normal small py-2">
                                {errors.email}
                            </div>
                        }
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="phone">Teléfono</label>
                        <input 
                            id="phone" 
                            type="text" 
                            value={phone} 
                            onChange={(e) => setphone(e.target.value)} 
                            placeholder="Teléfono" 
                            className={(errors.hasOwnProperty('phone') ? 'form-control is-invalid' : 'form-control')} 
                        />
                        {errors.hasOwnProperty('phone') &&
                            <div className="text-danger font-weight-normal small py-2">
                                {errors.phone}
                            </div>
                        }
                    </div>
                    <div className="form-group col-md-12">
                        <label htmlFor="address">Dirección</label>
                        <input 
                            id="address" 
                            type="text" 
                            value={address} 
                            onChange={(e) => setaddress(e.target.value)} 
                            placeholder="Dirección" 
                            className={(errors.hasOwnProperty('address') ? 'form-control is-invalid' : 'form-control')}  
                        />
                        {errors.hasOwnProperty('address') &&
                            <div className="text-danger font-weight-normal small py-2">
                                {errors.address}
                            </div>
                        }
                    </div>
                </div>
                <button disabled={sending} type="submit" className="btn btn-primary">
                    {(sending) ? <span><i className="fa fa-spinner fa-spin mr-2"></i>Cargando</span> : <span><i className="fa fa-pencil mr-2"></i>Actualizar</span>}
                </button>
            </form>
        </div>
    )
}

export default AccountSettings
