import React, {useState, useEffect} from 'react'
import SelectRole from '../SelectRole';
import axios from "axios";
import SelectPermissions from '../SelectPermissions';

function Stepthree({ btnCancel, type = "files", id, usersList = [], isShared, share }) {

    const [dangermessage, setdangermessage]     = useState('');
    const [errormessage,  seterrormessage]      = useState('');

    const [search, setsearch]                   = useState(true);
    const [loading, setLoading]                 = useState(true);
    const [adding, setadding]                   = useState(false);

    const [list, setlist]                       = useState(null);
    const [count, setcount]                     = useState(0);
    const [refresh, setrefresh]                 = useState(false);

    const [actualRole, setactualRole]           = useState(null);
    const [newpermissions, setnewpermissions]   = useState(null);

    const sendRole = () => {
        if(actualRole !== null){

            let data = {
                roles: [actualRole.value],
                permission: newpermissions.value
            }

            let url = "";

            if(type === "folder"){
                url = "/filemanager/shared-folders/"+id+"/add-roles/";
            }else{
                url = "/filemanager/shared-files/"+id+"/add-roles/";
            }

            setadding(true);

            axios({
                method: "POST",
                data,
                url
            }).then((res) => {
                console.log(res);
                setactualRole(null);
                setnewpermissions(null);
                setadding(false);
                getData();
            }).catch((err) => {
                console.log(err);
                setadding(false);
            });
            
        }else{
            seterrormessage("Debe seleccionar un Rol");
        }
    }

    const addRoleToList = () => {
        seterrormessage("");

        if(isShared !== undefined && isShared){

            sendRole();

        }else if(isShared !== undefined && !isShared){

            let url = "";

            if(type === "folder"){
                url = "/filemanager/share-folder/"+id+"/";
            }else{
                url = "/filemanager/share-file/"+id+"/";
            }

            axios({
                method: "POST",
                url
            }).then((res) => {
                console.log(res.data);
                sendRole();
                share();
            }).catch((err) => {
                console.error(err);
                if(err.response){
                    if(err.response.data && err.response.data.hasOwnProperty("non_field_errors")){
                        //share();
                    }
                }
            });
        }
    }

    const deleteRole = (idrole) => {
        let url = "";

        if(type === "folder"){
            url = "/filemanager/shared-folders/"+id+"/roles/"+idrole+"/";
        }else{
            url = "/filemanager/shared-files/"+id+"/roles/"+idrole+"/";
        }

        axios({
            url,
            method: "DELETE",
        }).then((res) => {
            console.log(res);
            getData();
        }).catch((err) => {
            console.error(err);
        });
    } 

    const changePermissions = (itemid,groupid,value) => {
        let url = "";
        
        if(type === "folder"){
            url = "/filemanager/shared-folders/"+id+"/roles/"+groupid+"/";
        }else{
            url = "/filemanager/shared-files/"+id+"/roles/"+groupid+"/";
        }

        axios({
            url,
            method: "PUT",
            data: {
                permission: value.value
            }
        }).then((res) => {
            console.log(res);
            getData();
        }).catch((err) => {
            console.error(err);
        });
    }


    const getData = () => {
        let url = "";
        if(type === "folder"){
            url = "/filemanager/shared-folders/"+id+"/roles/";
        }else{
            url = "/filemanager/shared-files/"+id+"/roles/";
        }

        axios.get(url).then((res) => {
            setlist(res.data);
            setLoading(false);
            console.log(res.data);
        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        if(loading){
            if(search){
                setsearch(false);
                getData();
            }
        }
    });

    return (
        <div>
            <div className="">
                {errormessage !== "" &&
                    <div className="mb-3 alert alert-warning">
                        {errormessage}
                    </div>
                }
                <div className="form-group">
                    <div className="row">
                        <div className="col-lg-8 mb-2">
                            <SelectRole value={actualRole} refresh={refresh} actualList={list} onChange={(value) => setactualRole(value)} />
                        </div>
                        <div className="col-lg-2 col-6">
                            <SelectPermissions 
                                onChange={(value) => setnewpermissions(value)} 
                                value={newpermissions} 
                            />
                        </div>
                        <div className="col-lg-2 col-6">
                            <button 
                                disabled={adding || (actualRole === null || newpermissions === null)}
                                onClick={() => addRoleToList()} 
                                className="btn btn-block w-100 btn-primary btn-sm"
                            >
                                {adding ? <i className="fa fa-spin fa-spinner"></i> : "Agregar"}
                            </button>
                        </div>
                    </div>
                </div>
                {(loading) &&
                    <div className="py-3">
                        <p className="mb-3 text-center">
                            <i className="fa fa-spin fa-spinner fa3x"></i>
                        </p>
                    </div>
                }
                {((Array.isArray(list) && list.length === 0) || list === null) &&
                    <div className="py-2">
                        <p className="mb-0 text-center">
                            Sin roles agregados
                        </p>
                    </div>
                }
                {(Array.isArray(list) && list.length > 0) &&
                    <div className="table-responsive mt-3">
                        <table className="table table-sm table-bordered pb-4">
                            <thead>
                                <tr>
                                    <th className="py-2">Nombre</th>
                                    <th className="py-2" style={{width: '220px'}}>Permiso</th>
                                    <th className="py-2 text-center" style={{width: '50px'}}>
                                        <i className="fa fa-times"></i>
                                    </th>
                                </tr>
                            </thead>
                            {(Array.isArray(list) && list.length > 0) &&
                                <tbody>
                                    {list.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td className="py-1">
                                                    {item.group.name}
                                                </td>
                                                <td className="py-1">
                                                    <div style={{width: "120px"}}>
                                                        <SelectPermissions 
                                                            onChange={(value) => changePermissions(item.id, item.group.id, value)} 
                                                            value={item.permission} 
                                                            normal={true}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="py-1">
                                                    <button onClick={() => deleteRole(item.group.id)} className="btn btn-sm">
                                                        <i className="fa fa-times text-danger"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            }

                            {!(list !== null && list.length > 0) &&
                                <tbody>
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            Sin Roles agregados.
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                }
            </div>
            <div className="text-right">
                {btnCancel}
            </div>
            <div className="pt-3">
                {dangermessage !== "" &&
                    <div className="mb-3 alert alert-danger text-center">
                        <small>
                            {dangermessage}
                        </small>
                    </div>
                }
            </div>
        </div>
    )
}

export default Stepthree
