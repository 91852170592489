import React, {useState, useEffect} from 'react'
import {Link, withRouter} from 'react-router-dom'
import axios from 'axios'
import {useSelector, useDispatch} from 'react-redux'

import InlineLoader from '../../../helpers/InlineLoader';
import {OverlayTrigger, Tooltip} from "react-bootstrap"
import { FileIcon, defaultStyles } from 'react-file-icon';
import { downloadURI, formatUrl, downloadFileFromUrl, downloadFunction } from '../../../utils/urls';
import BtnAddToFavourities from '../../../helpers/BtnAddToFavourities';
import ModalShowFile from '../../../helpers/ShowFile';
import ModalUpdateStatus from '../../../helpers/Files/ModalUpdateStatus';
import NameComponent from '../../../helpers/Files/NameComponent';
import ModalRenameFile from '../../../helpers/RenameFile';
import ModalDeleteFile from '../../../helpers/DeleteFile';
import ModalShareFile from '../../../helpers/ModalShareFile';

import DataGroupButtons from '../../../helpers/DataGroupButtons';

function Tag(props) {
    let id = props.match.params.id;
    let url = '/filemanager/public-files-by-tag/'+id+'/';

    const session = useSelector(state => state.session);
    const userPermissions = session.userData.permissions;

    const [loading, setloading] = useState(true);
    const [search, setsearch]   = useState(true);
    const [data, setdata]       = useState(null);

    const [fileSelected,   setfileSelected]         = useState(null);
    const [showModalStatus, setShowModalStatus] = useState(false);
    const [showModalEditCategory, setShowModalEditCategory] = useState(false);
    
    const dispatch        = useDispatch();
    const downloadList    = useSelector(state => state.dashboard.download_list);
    console.log("Lista de descargas:",downloadList);

    //show
    const [showModalShowFile, setShowModalShowFile] = useState(false);
    const handleCloseModalShowFile = () => {
      setShowModalShowFile(false);
    }
    const handleShowModalShowFile = () => {
      setShowModalShowFile(true)
    };

    const showFileNow = (file) => {
      setfileSelected(file);
      handleShowModalShowFile();
    }

    //rename
    const [showModalRenameFile, setShowModalRenameFile] = useState(false);
    const handleCloseModalRenameFile = () => {
      setShowModalRenameFile(false);
    }
    const handleShowModalRenameFile = () => {
      setShowModalRenameFile(true)
    };

    const RenameFileNow = (file) => {
      setfileSelected(file);
      handleShowModalRenameFile();
    }

    //delete
    const [showModalDeleteFile, setShowModalDeleteFile] = useState(false);
    const handleCloseModalDeleteFile = () => {
      setShowModalDeleteFile(false);
    }
    const handleShowModalDeleteFile = () => {
      setShowModalDeleteFile(true)
    };

    const deleteFileNow = (file) => {
      setfileSelected(file);
      handleShowModalDeleteFile();
    }

    //share
    const [showModalShareFile, setShowModalShareFile] = useState(false);
    const handleCloseModalShareFile = () => {
      setShowModalShareFile(false);
    }
    const handleShowModalShareFile = () => {
      setShowModalShareFile(true)
    };

    const shareFileNow = (file) => {
      setfileSelected(file);
      handleShowModalShareFile();
    }

    const getData = () => {
        axios({
            method: 'GET',
            url
        }).then(async (res) => {
            await setdata(null);

            console.log(res.data);
            setdata(res.data);
            setloading(false);
        }).catch((err) => {
            console.error(err);
        });
    }
  
    useEffect(() => {
        if(loading){
            if(search){
                setsearch(false);
                getData();
            }
        }
    }, []);

    const refresh = () => {
        setsearch(true);
        getData();
    }

    const showModal = (item, typeModal, typeItem) => {

        if(typeItem === "folder"){
          //setfolderSelected(item);
  
          switch (typeModal) {
            case "rename":
              //setShowModalRenameFolder(true);
              break;
  
            case "move":
              //setShowModalMoveFile(true);
              break;
  
            case "share":
              //setShowModalShareFolder(true);
              break;
  
            case "delete":
              //setShowModalDeleteFolder(true);
              break;
          
            default:
              break;
          }
        }else if(typeItem === "file"){
          setfileSelected(item);
  
          switch (typeModal) {
            case "rename":
              setShowModalRenameFile(true);
              break;
  
            case "move":
              //setShowModalMoveFile(true);
              break;
  
            case "share":
              setShowModalShareFile(true);
              break;
  
            case "delete":
              setShowModalDeleteFile(true);
              break;
  
            case "download":
              downloadFileFromUrl(null, formatUrl(item.file), item.name, downloadFunction, item, dispatch, downloadList)
              break;
  
            case "show":
              setShowModalShowFile(true);
              break;
  
            case "editStatus":
              setShowModalStatus(true);
            break;
  
            case "editCategory":
              setShowModalEditCategory(true);
            break;
          
            default:
              break;
          }
        }
    }

    return (
        <div>
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/etiquetas">
                    Etiquetas
                  </Link>
                </li>
                <li className="breadcrumb-item">
                    {Number(id) === 0 ? "Sin etiquetas" : id}
                </li>
              </ol>
            </div> 
            <div className="row mb-5 align-items-center">
                <div className="col-lg-12">
                    <div className="card m-0 ">
                        <div className="card-body py-3 py-md-2">
                            <div className="row align-items-center">
                                <div className="col-md-5 mb-md-0">
                                    <div className="media align-items-end">
                                        <div className="media-body ml-1">
                                            <h3 className="mb-0 text-black font-w600 fs-20">
                                                {Number(id) === 0 ? "Sin etiquetas" : "Ver etiqueta"}
                                            </h3>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
            <div className="row">
            <div className="col-xl-12">	
            <div className="tab-content">
            <div id="navpills-1" className="tab-pane active">
                {(loading) &&
                    <InlineLoader />
                }
                {!loading && data !== null &&
                    <div className="table-responsive table-hover fs-14">
                        <div id="example5_wrapper" className="dataTables_wrapper no-footer">
                            <table className="table display mb-4 dataTablesCard fs-14 dataTable no-footer" id="example5" role="grid" aria-describedby="example5_info">
                                <thead>
                                    <tr role="row">
                                        <th style={{width: '222px'}} className="sorting_asc" tabIndex={0} aria-controls="example5" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Archivo: activate to sort column descending">Archivo</th>
                                        <th className="text-center sorting" tabIndex={0} aria-controls="example5" rowSpan={1} colSpan={1} aria-label="Acción: activate to sort column ascending" style={{width: '568px'}}>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading && data !== null &&
                                        <>
                                            {data.length > 0 && data.map((element, index) => {
                                                let filename = element.name.split('.');
                                                let formattext = filename[filename.length - 1].toLowerCase();

                                                return (
                                                <tr key={index} role="row" className="odd">
                                                    <td className="sorting_1">
                                                        <div 
                                                            style={{width: 'auto'}} 
                                                            className="media align-items-center"
                                                        >
                                                                <div className={element.is_symbolic_link ? "is-symbolic-item" : ""}>
                                                                    {(element.thumbnail !== null && element.thumbnail !== '') 
                                                                    ?
                                                                    <img 
                                                                        className="img-fluid rounded mr-3 d-xl-inline-block" 
                                                                        width={70} 
                                                                        src={formatUrl(element.thumbnail)} 
                                                                        alt="Archivo" 
                                                                    />
                                                                    :
                                                                    <div style={{ width: "70px", marginRight: "15px" }}>
                                                                        <div style={{ width: "70px"}}></div>
                                                                        <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                                                    </div>
                                                                    }
                                                                </div>
                                                                <div className="media-body">
                                                                    <NameComponent
                                                                        item={element} 
                                                                        id={element.id} 
                                                                        name={element.name} 
                                                                        status={element.status} 
                                                                        status_name={element.status_name} 
                                                                        selectItem={setfileSelected}
                                                                        showModalStatus={() => setShowModalStatus(true)}
                                                                        //showBtn={element.permissions.can_change_status}
                                                                    />
                                                                    <span>Sábado, 24 de Julio 2020 04:55 PM</span>
                                                                </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-start">

                                                            <DataGroupButtons
                                                                page="tag"
                                                                type="file"
                                                                userPermissions={userPermissions} 
                                                                id={`btn-file-tag-${element.id}`} 
                                                                item={element}
                                                                permissions={element.permissions}
                                                               
                                                                showModal={showModal}
                                                            /> 
                                                            
                                                        </div>
                                                    </td>
                                                </tr>
                                                )
                                            })}

                                            {data.length === 0 &&
                                                <tr>
                                                    <td colSpan="2" className="text-center">
                                                        <p className="mb-0">
                                                            Sin archivos encontrados.
                                                        </p>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
            </div>
          </div>
        </div>

        {userPermissions.can_share_files &&
            <ModalShareFile  onlyenlace  refresh={refresh}  file={fileSelected}    show={showModalShareFile}      close={handleCloseModalShareFile} />
        }
        
        <ModalRenameFile ispublic   refresh={refresh}    file={fileSelected}    show={showModalRenameFile}     close={handleCloseModalRenameFile} />
        
        <ModalUpdateStatus refresh={refresh}  type="status"  file={fileSelected} show={showModalStatus} close={() => setShowModalStatus(false)} />
        <ModalUpdateStatus refresh={refresh}  type="category"  file={fileSelected} show={showModalEditCategory} close={() => setShowModalEditCategory(false)} />

        <ModalShowFile file={fileSelected}    show={showModalShowFile}       close={handleCloseModalShowFile} />
        <ModalDeleteFile  ispublic  refresh={refresh}    file={fileSelected}    show={showModalDeleteFile}     close={handleCloseModalDeleteFile} />
        
        </div>
    )
}

export default withRouter(Tag);
