const InlineLoader = (props) => {
    return (
        <div className={(props.class ? props.class : "") + " inline-loader"}>
            <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
            </div>
        </div>
    )
}

export default InlineLoader