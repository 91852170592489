export const onlyTextAndNumbers = (e) => {
    var regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ\u00f1\u00d1_ \-]+$");

    var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!regex.test(key)) {
      e.preventDefault();
      return false;
   }
}

export const onlyTextAndNumbersAndDot = (e) => {
   var regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ\u00f1\u00d1_. \-]+$");

   var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
   if (!regex.test(key)) {
     e.preventDefault();
     return false;
  }
}