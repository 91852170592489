import React, {useState, useEffect} from 'react'
import {
    Modal,
    Button,
    ProgressBar
} from 'react-bootstrap'
import axios from 'axios'
import {useSelector} from "react-redux"
import InlineLoader from './InlineLoader';

function ModalMove(props) {

    let urlthree = "/filemanager/my-drive/get-three-folders/";

    const [loading, setloading]                         = useState(true);
    const [search, setsearch]                           = useState(true);
    const [three, setthree]                             = useState(null);
    const [newThree, setnewThree]                       = useState(null);
    const [navigationFolders, setnavigationFolders]     = useState([]);

    const [showModal,   setshowModal]                   = useState(false);

    const [sending, setsending]                         = useState(false);
    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');
    const [progress, setprogress]                       = useState(0);
    const [count,    setcount]                          = useState(0);

    const [file,   setfile]                             = useState(null);
    const [folder, setfolder]                           = useState(null);

    const [errors,   seterrors]                         = useState({});

    const [name, setname]                               = useState((props.file) ? props.file.name : '');
    const [format, setformat]                           = useState('');
    
    const [status, setstatus]                           = useState(null);

    const statusList = useSelector(state => state.dashboard.status);

    const getData = () => {
        axios.get(urlthree)
        .then((res) => {

            setthree(res.data);
            setnewThree(res.data[0]);

            setloading(false);

            console.log(res.data);

        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setnavigationFolders([]);
                setthree(null);
                setsearch(true);
                setloading(true);
                setsuccessmessage('');
                seterrormessage('');
                setprogress(0);
                setcount(0);
            }

            if(loading){
                if(search){
                    setsearch(false);
                    getData();
                }
            }else{

                if(props.file !== file && props.file !== null && props.file !== undefined && !search){
                    console.log('Actualizando archivo');
                    setsending(false);
                    
                    setsuccessmessage('');
                    seterrormessage('');
                    setprogress(0);
                    setcount(0);

                    setfolder(null);
                    setfile(props.file);
                } 

                if(props.folder !== folder && props.folder !== null && props.folder !== undefined && !search){
                    
                    //console.log('Actualizando carpeta');
                    //console.log(folder);
                    //console.log(props.folder);

                    setsending(false);
                    
                    setsuccessmessage('');
                    seterrormessage('');
                    setprogress(0);
                    setcount(0);

                    setfolder(props.folder);
                    setfile(null);
                } 

            }
        }else{
            setshowModal(props.show);
            setfile(null);
        }
    });

    const moveTo = (e) => {
        let url = '';

        let idFolder = 0;
        if(navigationFolders.length === 0){
            idFolder = 0
        }else{
            let newNavigationFolders = navigationFolders;
            idFolder = newNavigationFolders[newNavigationFolders.length - 1];
        }

        if(file !== null){
            url = `/filemanager/my-drive/move-file/${props.file.id}/to/${idFolder}/`;
        }else if(folder !== null){
            url = `/filemanager/my-drive/move-folder/${folder.id}/to/${idFolder}/`;
        }
                
        //console.log(idFolder);

        setsuccessmessage('');
        seterrormessage('');  
        setsending(true);
        seterrors({});

        
        axios({
            url: url,
            method: 'put',
        }).then((res) => {

            console.log(res.data);
            setsending(false);
            
            if(file !== null){
                setsuccessmessage('Archivo movido exitosamente');
            }else if(folder !== null){
                setsuccessmessage('Carpeta movida exitosamente');
            }

            //refresh
            props.refresh();

            setTimeout(() => {
                props.close();

                setsuccessmessage('');
                seterrormessage(''); 

                setfile(null);
                setfolder(null);

                setnewThree(null);
                setthree(null);

                setloading(true);
                setsearch(true);

                setnavigationFolders([]);

                seterrors({});
                setsending(false);
                

            }, 800);

        }).catch((err) => {
            console.error(err);

            
                if(err){
                    setsending(false);
                    if(err.response){
                        let errors = err.response.data;

                        if(errors){
                            seterrors(errors);
                        }

                        if(errors.hasOwnProperty('non_field_errors')){
                            seterrormessage(err.response.data.non_field_errors);
                        }

                        if(errors.hasOwnProperty('detail')){
                            seterrormessage(err.response.data.detail);
                        }
                    }
                }
            
        });
        
    }

    const backToDirectory = () => {
        let newNavigation = navigationFolders;
        newNavigation.pop();

        setnavigationFolders(newNavigation);
        updateThree();
    }

    const updateThree = () => {
        let newThree = [];
        let it = 0;

        it = navigationFolders.length;

        if(three !== null){
            if(navigationFolders.length === 0){
                newThree = three[0];
                setnewThree(newThree);
            }else if(navigationFolders.length > 0){
                let directorySelected = three[0];

                for (let j = 0; j < it; j++) {
                    let folderId = navigationFolders[j];
                    //console.log("Ingresando al directorio");
                    //console.log(folderId);

                    if(directorySelected.children){
                        let findDirectory = directorySelected.children.find(item => { 
                            if(item.id === folderId){
                                //console.log("pasando al directorio", item);
                                directorySelected = item;
                                return item.id;
                            } 

                            return undefined;
                        });

                        //console.log(findDirectory);
                    }
                }

                //console.log(findDirectory);
                //newThree = findDirectory;

                newThree = directorySelected;
                setnewThree(newThree);
            }
        }
    }

    const selectDirectory = (id) => {
        let newNavigation = navigationFolders;
        newNavigation.push(id);

        setnavigationFolders(newNavigation);
        updateThree();
    }

    
    if(three !== null && newThree !== null){
        //console.log(newThree);
        //console.log(newThree.children);
        //console.log(navigationFolders);
        //console.log(file);
        //console.log(folder);
    }

    return (
        <div>
            <Modal animation={false} show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <strong>Mover {props.file ? 'archivo' : ''} {props.folder ? 'carpeta' : ''}</strong>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(successmessage !== '') &&
                        <div className="mb-3 alert alert-success">
                            <p className="mb-0 font-weight-bold">{successmessage}</p>
                        </div>
                    }
                
                    { (progress > 0) &&
                        <div className="py-3">
                            <ProgressBar variant="success" animated now={progress} />
                        </div>
                    }

                    {(loading) &&
                        <InlineLoader />
                    }

                    {(
                        (three !== null && file   !== null && successmessage === '') ||
                        (three !== null && folder !== null && successmessage === '')
                    ) &&
                        <div className="three list-group">
                            <div className="alert shadow-sm alert-info">
                                <p className="mb-0">
                                    {(file !== null) && <span>Nombre:</span>} 
                                    {(folder !== null) && <span>Nombre:</span>}

                                    {(file !== null) && <span className="font-weight-bold"><i className="fa fa-file mr-2 ml-2"></i>{file.name}</span>}
                                    {(folder !== null) && <span className="font-weight-bold"><i className="fa fa-folder mr-2 ml-2"></i>{folder.name}</span>}
                                </p>
                            </div>
                            <h5 className="mb-3">
                                Esta viendo: 
                                {(navigationFolders.length > 0) &&
                                    <button onClick={() => backToDirectory()} className="btn-unstyled ml-2 mr-2">
                                        <i className="fa fa-angle-left"></i>
                                    </button>
                                }
                                <strong className="text-primary font-weight-bold ml-2">
                                    <i className="fa fa-folder mr-2"></i>{newThree.data.name}
                                </strong>
                            </h5>

                            {newThree.hasOwnProperty("children") && Array.isArray(newThree.children) 
                            ?
                                <>
                                    {newThree.children.map((item, key) => {
                                        return (
                                            <li 
                                                onClick={() => selectDirectory(item.id)}
                                                style={{cursor:"pointer"}} 
                                                className="list-group-item list-group-item-action" 
                                                key={key}
                                            >
                                                <i className="fa fa-folder mr-3"></i> 
                                                {item.data.name}
                                            </li>
                                        )
                                    })}
                                </>
                            :
                                <li className="list-group-item list-group-item-action text-center">
                                    Sin directorios
                                </li>
                            }
                        </div>
                    }

                    {(errormessage !== '') &&
                        <div className="mb-3 alert alert-danger">
                            <p className="mb-0 font-weight-bold">{errormessage}</p>
                        </div>
                    }
                
                </Modal.Body>
                <Modal.Footer>
                    {(file !== null || folder !== null) &&
                        <Button disabled={sending} onClick={(e) => moveTo(e)} variant="primary" type="button" >
                            {(sending) 
                            ? 
                                <span>
                                    <i className="fa fa-spinner fa-spin mr-2"></i>Cargando
                                </span> 
                            : 
                                <span>
                                    Mover
                                </span>
                            }
                        </Button>
                    }
                    <Button variant="light" onClick={props.close}>
                        <i className="fa fa-times mr-2"></i>Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalMove;
