import React, {useState, useEffect, useRef} from 'react'
import {
    Modal,
    Button,
    ProgressBar
} from 'react-bootstrap'
import axios from 'axios'
import Loader from './Loader';
import InlineLoader from './InlineLoader';

function ModalCopyFileNow(props) {

    const [showModal,       setshowModal]               = useState(false);

    const [loading, setloading]                         = useState(true);
    const [copying, setcopying]                         = useState(false);
    const [sending, setsending]                         = useState(false);

    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');
    const [progress, setprogress]                       = useState(0);

    const [files, setfiles]                             = useState(null);
    const [file,   setfile]                             = useState(null);

    const buttonref                                     = useRef(null);
    const handleClick = () => {
        buttonref.current.focus();
    }

    // console.log(file);
    useEffect(async () => {
        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setfile(null);
                setsuccessmessage('');
                seterrormessage('');

                setloading(true);
                setsending(false);
                setcopying(false);
            }

            if(!props.multiple){
                if(props.file !== file && props.file !== null && props.file !== undefined){
                    // console.log('Actualizando archivo:',props.file);
                    
                    setsuccessmessage('');
                    seterrormessage('');
                    setfile(props.file);
    
                    setloading(false);
                } 
            }else{
                if(props.items){

                    setsuccessmessage('');
                    seterrormessage('');
                    setfiles(props.items);
                    
                    setloading(false);

                }
            }
            
            if(!sending && !loading){
                setsending(true);
            }

            if(sending && !loading && !copying){
                setcopying(true);
                copyFile();
            }
            
        }else{
            setshowModal(props.show);
        }
    });

    const copyFile = () => {
        let url = '/filemanager/copy-file/'+file.id+'/';

        setsuccessmessage('');
        seterrormessage('');

        console.log("copiando..");

        let data = {
            to:    props.ispublic ? "C" : "D",
            to_id: props.folder ? props.folder : "0"
        }

        console.log(data);
        console.log(sending);
        
        if(sending){
            axios({
                url: url,
                method: 'POST',
                data
            }).then((res) => {
                console.log(res.data.data);
                setsuccessmessage('Archivo copiado');
                //refresh

                // setloading(true);
                // setsending(false);
                // setcopying(false);

                setTimeout(async () => {
                    setfile(null);
                    setsuccessmessage('');
                    seterrormessage('');
                    await props.close();

                    setTimeout(() => {
                        props.refresh();
                    }, 300);
                    
                }, 500);

            }).catch((err) => {

                
                console.error(err);
                setsending(false);
                let consulterrors = err.response.data;
                console.log(consulterrors);

                if(consulterrors.hasOwnProperty("non_field_errors")){
                    seterrormessage(err.response.data.non_field_errors);
                }else if(consulterrors.hasOwnProperty("detail")){
                    seterrormessage(err.response.data.detail);
                }
                

            });
        }
    }

    return (
        <div>
            <Modal show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                    {!props.multiple &&
                        <Modal.Title>
                            <strong>Copiando el archivo <strong className="text-primary">{file !== null && file.hasOwnProperty('name') ? file.name : ''} .. </strong></strong>
                        </Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>

                    {/* 
                        <button onClick={() => copyFile()} className='d-none' ref={buttonref}>button</button>
                    */}

                    {(errormessage !== '') &&
                        <div className="mb-3 alert alert-danger">
                            <p className="mb-0 font-weight-bold">{errormessage}</p>
                        </div>
                    }
                    { (progress > 0) &&
                        <div className="py-3">
                            <ProgressBar variant="success" animated now={progress} />
                        </div>
                    }

                    {(successmessage !== '') 
                        ?
                            <div className="mb-3 alert alert-success">
                                <p className="mb-0 font-weight-bold">{successmessage}</p>
                            </div>
                        :
                            <InlineLoader class="minimal" />
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalCopyFileNow;
