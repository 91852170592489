import React, {useState, useEffect, Fragment} from 'react'
import {useSelector} from "react-redux"
import {Link} from "react-router-dom"
import axios from "axios"
import imgHome from '../../../../static/images/svg/welcom-card.svg';
import CardSearch from '../../../helpers/CardSearch';
import InlineLoader from '../../../helpers/InlineLoader';
import { formatUrl } from '../../../utils/urls';

import {OverlayTrigger, Tooltip } from "react-bootstrap"

function Home() {

    const [loading, setloading]                 = useState(true);
    const [search,  setsearch]                  = useState(true);
    const [statsCategories, setstatsCategories] = useState(null);

    const banner = useSelector(state => state.session.settings.filemanager.banner.file);
    console.log(banner);

    let colors = ["danger","primary","success","info"];

    useEffect(() => {
      if(loading){
        if(search){
          setsearch(false);
            let urlSearch = "/filemanager/root-categories-stats";
            axios.get(urlSearch).then((res) => {
              console.log(urlSearch);
              setstatsCategories(res.data);
              setloading(false);
              console.log(res.data);
            }).catch((err) => {
              console.error(err);
            });
        }
      }
    });

    let idhelper = 4;

    let bannerfilename    = banner.split('.');
    let bannerformattext  = bannerfilename[bannerfilename.length - 1].toLowerCase();

    return (
        <div className="">
        <div className="row">
          <div className="col-xl-12">
            {/* 
              <div className="welcome-card rounded pl-5 pt-5 pb-4 mt-0 position-relative mb-3 mb-sm-4 mb-lg-5 mt-lg-4">
                <h4 className="text-warning">Bienvenido al administrador de archivos de Mobil</h4>
                <p>Repositorio de archivos en el cual podrás subir y buscar el contenido final tus piezas.</p>
                <img src={imgHome} alt="" className="position-absolute" />
              </div>
            */}

            {bannerformattext === "svg" 
            ?
              <object data={formatUrl(banner)} type="image/svg+xml" style={{width: "50%"}}>
                <img src={formatUrl(banner)} />
              </object>
            :
              <img src={formatUrl(banner)} className="w-100" alt="Banner home" />
            }

          </div>
          <CardSearch />
          {loading &&
            <InlineLoader />
          }
          {Array.isArray(statsCategories) && statsCategories !== null &&
            <>
              <h4 className="card-title mb-1 col-xl-12">Acceso rápido - Categorías</h4>
              <p className="mt-4 pt-2">
              </p>
                {statsCategories.length > 0 && statsCategories.map((item, key) => {
                  idhelper++;
                  //console.log(idhelper % colors.length);
                  //bg-${colors[idhelper % colors.length]}
                  return (
                    <div key={key} className="col-xl-3 col-lg-6 col-sm-6">
                      {(item.permissions.can_open) 
                      ?
                        <Fragment>
                          {item.icon !== null 
                            ?
                            <Link to={"/categoria/"+item.id} style={{backgroundColor: item.background_icon}} className={"widget-stat card "+ ``}>
                              <div className="card-body p-3">
                                <div className="media">
                                  <span className="mr-0" style={{backgroundColor: "rgba(255,255,255,.3)"}}>
                                    <img src={item.icon} className="w-100" />
                                    <i className="flaticon-381-diamond d-none" />
                                  </span>
                                  <div className="media-body text-white text-right">
                                    <p className="mb-1">{item.name}</p>
                                    <h3 className="text-white">
                                      {item.total_public_files_count}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </Link>
                            :
                            <Link to={"/categoria/"+item.id} className={"widget-stat card "+ `bg-${colors[idhelper % colors.length]}`}>
                              <div className="card-body p-3">
                                <div className="media">
                                  <span className="mr-0" style={{backgroundColor: "rgba(255,255,255,.3)"}}>
                                    <i className="flaticon-381-diamond" />
                                  </span>
                                  <div className="media-body text-white text-right">
                                    <p className="mb-1">{item.name}</p>
                                    <h3 className="text-white">
                                      {item.total_public_files_count}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          }
                        </Fragment>
                      :
                        <Fragment>
                          {item.icon !== null 
                          ?
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id={`button-home-category-${item.id}`}>Usted no tiene permisos para ver esta categoría</Tooltip>}
                          >
                            <div className={"widget-stat card "+ `bg-${colors[idhelper % colors.length]}`}>
                              <div className="card-body p-3">
                                <div className="media">
                                  <span className="mr-0">
                                    <img src={item.icon} className="w-100" />
                                    <i className="flaticon-381-diamond d-none" />
                                  </span>
                                  <div className="media-body text-white text-right">
                                    <p className="mb-1">{item.name}</p>
                                    <h3 className="text-white">
                                      {item.total_public_files_count}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </OverlayTrigger>
                          :
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id={`button-home-category-${item.id}`}>Usted no tiene permisos para ver esta categoría</Tooltip>}
                          >
                          <div className={"widget-stat card "+ `bg-${colors[idhelper % colors.length]}`}>
                            <div className="card-body p-3">
                              <div className="media">
                                <span className="mr-0">
                                  <i className="flaticon-381-diamond" />
                                </span>
                                <div className="media-body text-white text-right">
                                  <p className="mb-1">{item.name}</p>
                                  <h3 className="text-white">
                                    {item.total_public_files_count}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          </OverlayTrigger>
                          }
                        </Fragment>
                      }
                    </div>
                  )
                })}
              <p />
            </>
          }
        </div>
      </div>
    )
}

export default Home
