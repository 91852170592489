import React, {useState, Fragment} from 'react'
import { FileIcon, defaultStyles } from 'react-file-icon';
import {Link} from "react-router-dom"
import axios from 'axios'
import { formatUrl } from '../utils/urls';
import InlineLoader from './InlineLoader';
import { OverlayTrigger, Tooltip, Popover, PopoverContent } from 'react-bootstrap';

function CardSearchTopMobile() {

    const [textsearch,  settextsearch]  = useState('');
    const [searching,   setsearching]   = useState(false);
    const [data,        setdata]        = useState(null);
    const [errors,      seterrors]      = useState({});

    const [ishover, setishover]         = useState(false);

    const search = (e) => {
        e.preventDefault();
        e.stopPropagation();

        seterrors({});

        let transformText = encodeURI(textsearch.trim().replace(/\s+/gi,' '));
        let textValues    = transformText.split('%20');
        let textResult    = textValues.join('+');

        let urlprivate = "/filemanager/search-private-files/?q="+textResult;
        //let urlpublic = "/filemanager/search-public-files/?q="+textResult;

        setsearching(true);

        axios.get(urlprivate).then((res) => {
            console.log(res.data);
            setdata(res.data);
            setsearching(false);
        }).catch((err) => {
            console.error(err);
            setsearching(false);

            let response = err.response;

            if(typeof response === "object" && response.hasOwnProperty("data")){
                seterrors(response.data);
            }
        });
    }
    
    const reset = () => {
        settextsearch('');
        setsearching(false);
        setdata(null);
    }

    const changetext = (value) => {
        if(value === ''){
            reset();
            seterrors({});
        }else{
            settextsearch(value);
            seterrors({});
        }
    }

    /*
        <Tooltip style={{minWidth: "200px"}} id={`tooltip-search-top-helper`}>
            <div>
                <p className="small mb-1">
                    Para buscar archivos, digite un texto y a continuación haga clic en el ícono de la lupa o presione la tecla Enter. La búsqueda se realiza sobre los nombres de archivo. Solo se consideran los archivos privados (los archivos que se encuentran en la unidad del usuario).
                </p>   
                <p className="small">
                    Por ejemplo: Si buscara el texto "ami", se listarán todos los archivos privados (de la unidad del usuario), cuyos nombres contienen el texto "ami", como: camión, amigo, camino, etc.
                </p>      
            </div>      
        </Tooltip>
    */

    const isBlur = () => {
        setishover(false);
        setTimeout(() => {
            //setdata(null);
        }, 200);
    }

    const isFocus = () => {
        setishover(true);
    }

    return (
        <div className="searchbar-mobile-container"> 
            <form onSubmit={(e) => search(e)} className="w-100 d-flex" action="">
            <div className={`searchbar-mobile ${ishover ? "is-hover" : ""}`}>
                <div className="input-group">
                    <input 
                        value={textsearch} 
                        onChange={(e) => changetext(e.target.value)} 
                        className="form-control" 
                        type="search" 
                        placeholder="Buscar en mi unidad..." 
                        aria-label="Search" 
                        onFocus={() => isFocus()}
                        onBlur={() => isBlur()}
                    />
                    <div className="input-group-append">
                        <button className="btn px-4 bg-white" type="submit">
                            <i style={{fontSize: "19px"}} className="fa fa-search"></i>
                        </button>
                    </div>
                    <div className="input-group-append">
                        <button id="btnCloseSearchMobile" className="btn px-4 bg-white" type="button">
                            <i style={{fontSize: "19px"}} className="fa fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            </form>

            {((data !== null && Array.isArray(data)) || searching || errors.hasOwnProperty("detail")) &&
                <div className="search-results-container results-top">
                    {!searching &&
                        <div className="card shadow p-4">
                            {(data !== null && data.length > 0) &&
                                <ul className="search-results-container-data">
                                    {data.map((item, key) => {
                                        let filename = item.name.split('.');
                                        let formattext = filename[filename.length - 1].toLowerCase();
                                        
                                        return (
                                            <li className="d-flex link-item-preview-list w-100 align-items-center" key={key}>
                                                <Link 
                                                    onClick={() => reset()}
                                                    className="d-flex w-100 py-2 align-items-center" 
                                                    to={"/file/preview/"+item.id+"/"}
                                                >
                                                    {(item.thumbnail !== null && item.thumbnail !== '') 
                                                        ?
                                                        <img 
                                                            style={{marginRight: "10px"}}
                                                            className="img-fluid rounded d-xl-inline-block" 
                                                            width={20} 
                                                            src={formatUrl(item.thumbnail)} 
                                                            alt="img-thumb" 
                                                        />
                                                        :
                                                        <div style={{ width: "20px", marginRight: "10px" }}>
                                                            <div style={{ width: "20px"}}></div>
                                                            <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                                        </div>
                                                    }
                                                    <span className="mb-0 small d-inline-flex text-dark">
                                                        {filename}
                                                    </span>
                                                </Link>
                                            </li>
                                        )

                                    })}
                                </ul>
                            }

                            {(errors.hasOwnProperty("detail")) &&
                                <div className="alert alert-danger small mb-0 font-weight-bold">
                                    <i className="fa fa-exclamation mr-2"></i>{errors.detail}
                                </div>
                            }

                            {(data !== null && data.length === 0 && !errors.hasOwnProperty("detail")) &&
                                <p className="text-center mb-0">
                                    <i className="fa fa-exclamation-triangle mr-2"></i>
                                    Sin resultados.
                                </p>
                            }
                        </div>
                    }
                    

                    {searching &&
                        <div className="card p-4">  
                            <InlineLoader />
                            <p className="mb-0 d-none text-center">
                                    <i className="fa fa-spin fa-spinner fa-1x text-muted"></i>
                            </p>
                        </div>    
                    }
                </div>
            }
        </div>
    )
}

export default CardSearchTopMobile;
