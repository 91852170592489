import { set_download_list } from "../../store/dashboard/actions";

export const formatUrl = (url) => {
    let baseUrl = 'http://mobilrepoapitest.rlsoluciones.com';
    let finalUrl = '';

    if(url.includes('http://') || url.includes('https://')){
        finalUrl = url;
    }else{
        finalUrl = baseUrl+url;
    }

    return finalUrl;
}

export const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = '__blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const copyToClipboard = text => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  return true;
};

export const downloadFileFromUrl = async (e = null, fileUrl, filename, downloadFunction = null, file = null, dispatch = null, downloadedFilesInState = null) =>  {

    if(e !== null){
      e.preventDefault();
    }
    
    let controller  = new AbortController();
    let signal      = controller.signal;

    let response    = await fetch(fileUrl, {
      method: "get",
      signal: signal
    });

    const contentLength = response.headers.get('content-length');
    console.log('Descargando archivo, tamaño:'+contentLength+' bytes');

    const total = parseInt(contentLength, 10);
    let loaded = 0;

    function cancelRequest () {
      controller.abort();
    }

    const res = new Response(new ReadableStream({
      async start(controller) {
        const reader = response.body.getReader();
        for (;;) {
          const {done, value} = await reader.read();

          if (done){ 
            break;
          }

          loaded += value.byteLength;
          //console.log((total / loaded) * 100);
          //progress({loaded, total})
          let actualProgress = (loaded * 100) / total;

          //console.log(actualProgress);
          controller.enqueue(value);

          if(downloadFunction !== null && file !== null && dispatch !== null && downloadedFilesInState !== null){
            downloadFunction(actualProgress, cancelRequest, file, dispatch, downloadedFilesInState);
          }
        }
        controller.close();
      },
    }));

    const blob = await res.blob();
    console.log("Archivo descargado");
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download',filename,);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export const downloadFunction = (progress, signaId, file = null, dispatch = null, downloadedFilesInState = null) => {
  console.log("comenzando descarga");

  if(dispatch !== null) {
    let downloadList = downloadedFilesInState;
    
    if(file !== null){
      console.log("==================================");
      console.log("descargando:"+progress);
      console.log(signaId);

      let idfile                  = file.id;
      let newDownloadList         = downloadList;
      let thisFileInDownloadList  = newDownloadList.find(item => item.idFile === idfile);

      if(thisFileInDownloadList !== undefined && thisFileInDownloadList !== null){

        let thisIndex = newDownloadList.indexOf(thisFileInDownloadList);
        newDownloadList[thisIndex].progress = progress;
        dispatch(set_download_list(newDownloadList));

        /*
        if(progress < 100){

          let thisIndex = newDownloadList.indexOf(thisFileInDownloadList);
          newDownloadList[thisIndex].progress = progress;
          dispatch(set_download_list(newDownloadList));

        }else{

          let listWithoutItem  = newDownloadList.filter(item => item.idFile !== idfile);
          newDownloadList      = listWithoutItem;

          setTimeout(() => {
            dispatch(set_download_list(newDownloadList));
          }, 1000);

        }
        */
      }else{

        let newDownload = {
          signal :  signaId,
          idFile :  idfile,
          fileName: file.name,
          progress: progress
        }

        newDownloadList.push(newDownload);
      }

      dispatch(set_download_list(newDownloadList));
    }
  }
}