import React from 'react'
import {Switch, Route, Redirect, Link} from 'react-router-dom'

//layout
//import PublicHeader from './layout/Header';

//components
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ForgotSuccess from './pages/ForgotSuccess';
import NewPassword from './pages/NewPassword';
import File from './pages/shared/File';
import Folder from './pages/shared/Folder';
import Category from './pages/shared/Category';
import Logo from '../../helpers/Logo';

function NotFound(){
    return (
      <div>
        <div className="dashboard-content-page-container page-not-found">
        <div className="container">
            <div className="row align-items-center min-vh-100 justify-content-center">
              <div className="col-10">
                <div className="not-found-text text-center">
                  <h1 className="font-weight-bold mb-2" style={{fontSize: '100px'}}>404</h1>
                  <h5 className="mt-3 mb-4">La página que buscas no ha sido encontrada</h5>
                  <Link to="/" className="btn btn-primary">
                      Ir al inicio <i className="fa fa-undo-alt"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

const PublicRoute = ({Component, dataRoute = [], FooterType="default", layout = false, ...rest}) => {
    if(layout){
        return (
        <Route
            {...rest}
            render={
                props =>
                <div className="mobil-app-container">
                    <div className="public-content-page">
                        <div className="pt-5">
                            <header className="text-center mb-5">
                                <div className="row justify-content-center">
                                    <div className="col-md-2">
                                        <Logo />
                                    </div>
                                </div>
                            </header>
                            <div className="container">
                                <Component 
                                    dataRoute={dataRoute}
                                    {...props} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
        )
    }else{
        return (
            <Route
                {...rest}
                render={
                    props =>
                    <div className="mobil-render-app-container">
                        <div className="public-content-page">
                            <Component 
                                dataRoute={dataRoute}
                                {...props} 
                            />
                        </div>
                    </div>
                }
            />
        )
    }
}

function Public() {
    return (
        <Switch>
            {/*Home*/}
            <PublicRoute exact path="/login/"                                   Component={Login} />
            <PublicRoute exact path="/accounts/password-reset/"                 Component={ForgotPassword} />
            <PublicRoute exact path="/accounts/password-reset/send/"            Component={ForgotSuccess} />
            <PublicRoute exact path="/accounts/password-reset/confirm/:token"   Component={NewPassword} />

            <PublicRoute exact path="/shared-files/:uuid"                           layout={true}   Component={File} />

            <PublicRoute exact path="/shared-folder/:uuid"                          layout={true}   Component={Folder} />
            <PublicRoute exact path="/shared-folder/:uuid/subfolder/:id"            layout={true}   Component={Folder}  dataRoute={{type: "subfolder"}} />

            <PublicRoute exact path="/shared-category/:uuid"                        layout={true}   Component={Category} />
            <PublicRoute exact path="/shared-category/:uuid/subcategory/:id"        layout={true}   Component={Category}  dataRoute={{type: "subcategory"}} />

            <Redirect from='/' to="/login" />
            <Redirect from='/dashboard' to="/login" />
        </Switch>
    )
}

export default Public;