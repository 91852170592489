import React, {useState, useEffect} from 'react'
import {
    Modal,
    Button,
    ProgressBar
} from 'react-bootstrap'
import axios from 'axios'

function ModalDeleteFile(props) {

    const [showModal,       setshowModal]  = useState(false);

    const [sending, setsending]                         = useState(false);
    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');
    const [progress, setprogress]                       = useState(0);

    const [file,   setfile]                         = useState(null);

    useEffect(() => {
        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setsending(false);
                setsuccessmessage('');
                seterrormessage('');
                setfile('');
            }

            if(props.file !== file && props.file !== null && props.file !== undefined){
                console.log('Actualizando archivo:',props.file);
                setsending(false);
                setsuccessmessage('');
                seterrormessage('');
                setfile(props.file);
            } 
        }else{
            setshowModal(props.show);
        }
    });

    const deleteFile = (e) => {
        let url = '/filemanager/my-drive/files/'+file.id+'/';

        if(props.isShare){
            if(props.parent){
                url = '/filemanager/shared-with-me/folders/'+props.parent+'/files/'+file.id+'/';
            }

            if(props.subfolder){
                url = '/filemanager/shared-with-me/folders/'+props.parent+'/descendant/'+props.subfolder+'/files/'+file.id+'/';
            }
        }

        if(props.ispublic){
            url = `/filemanager/public-file/${file.id}/`;
        }

        setsending(true);
        setsuccessmessage('');
        seterrormessage('');

        axios({
            url: url,
            method: 'delete',
        }).then((res) => {
            console.log(res.datadata);
            setsending(false);
            setsuccessmessage('Archivo eliminado correctamente');
            //refresh
            props.refresh();
            setTimeout(() => {
                props.close();
            }, 1000);
        }).catch((err) => {
            console.error(err);
            setsending(false);
            let consulterrors = err.response.data;
            console.log(consulterrors);

            if(consulterrors.hasOwnProperty("non_field_errors")){
                seterrormessage(err.response.data.non_field_errors);
            }else if(consulterrors.hasOwnProperty("detail")){
                seterrormessage(err.response.data.detail);
            }
        });
    }

    return (
        <div>
            <Modal show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <strong>¿Desea eliminar el archivo <strong className="text-primary">{file !== null && file.hasOwnProperty('name') ? file.name : ''}</strong>?</strong>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(successmessage !== '') &&
                        <div className="mb-3 alert alert-success">
                            <p className="mb-0 font-weight-bold">{successmessage}</p>
                        </div>
                    }
                    {(errormessage !== '') &&
                        <div className="mb-3 alert alert-danger">
                            <p className="mb-0 font-weight-bold">{errormessage}</p>
                        </div>
                    }
                    { (progress > 0) &&
                        <div className="py-3">
                            <ProgressBar variant="success" animated now={progress} />
                        </div>
                    }
                    <h4 className="font-weight-bold text-muted">
                        <strong>Nota:</strong> Esta acción no se puede deshacer.
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    {(successmessage === '') &&
                        <Button disabled={sending} onClick={(e) => deleteFile(e)} variant="primary" type="button" >
                            {(sending) ? <span><i className="fa fa-spinner fa-spin mr-2"></i>Cargando</span> : <span><i className="fa fa-ckeck mr-2"></i>Eliminar</span>}
                        </Button>   
                    }
                    <Button variant="light" onClick={props.close}>
                        <i className="fa fa-times mr-2"></i>Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalDeleteFile;
