import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link} from "react-router-dom"
import {Modal,Button, Nav} from 'react-bootstrap'
import axios from 'axios'
import InlineLoader from './InlineLoader';
import { copyToClipboard } from '../utils/urls';
import SelectRole from './SelectRole';
import SelectPermissions from './SelectPermissions';
import Stepthree from './share/Stepthree'
import StepTwo from './share/StepTwo'

function ModalShareCategory(props) {

    const session = useSelector(state => state.session);
    const userPermissions = session.userData.permissions;

    let activeDefaultStep =  0;
    let onlyenlace = props.onlyenlace ? true : false;

    if(2 === 2){
        activeDefaultStep = 1;
    }

    const [loading,         setloading]     = useState(true);
    const [search,          setsearch]      = useState(true);

    const [showModal,       setshowModal]   = useState(false);
    const [step,            setstep]        = useState(activeDefaultStep);
    const [message,         setmessage]     = useState("");

    const [category,        setcategory]    = useState(null);
    const [uuid,            setuuid]        = useState('');

    //filedata
    const [name, setname]                   = useState((props.category) ? props.category.name : '');
    const [format, setformat]               = useState('');

    let isPublic = props.ispublic ? true : false;

    let urlpublicpathtoFiles = "/shared-category/";

    useEffect(() => {
        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
            }

            if(props.category !== category && props.category !== null && props.category !== undefined){
                console.log(props.category);

                setcategory(props.category);
                //(props.file.is_shared);
    
                //let filename = props.file.name.split('.');
                //let formattext = filename[filename.length - 1];
                //setformat(formattext);
                //let filenametext = props.file.name.substring(0, props.file.name.length - (formattext.length + 1));
                setname(props.category.name);

                if(loading){
                    if(search){
                        setsearch(false);
                        const getDataShared = () => {
                            let urlGet = "/filemanager/get-uuid-category/"+props.category.id;

                            axios.get(urlGet).then((res) => {
                                console.log(res.data);
                                setuuid(res.data.uuid);
                                setloading(false);
                            }).catch((err) => {
                                console.error(err);
                            }); 
                        }
        
                        getDataShared();
                    }
                }
            } 

        }else{
            setloading(true);
            setsearch(true);
            setstep(activeDefaultStep);
            setname("");
            //setformat("");
            setcategory(null);
            setshowModal(props.show);
        }
    });

    const copy = (e, text) => {
        e.preventDefault();
        copyToClipboard(text);
        setmessage("Url copiada!");
        setTimeout(() => {
            setmessage("");
        }, 400);
    }

    const share = () => {
        //setIsShared(true);
        props.refresh();
    }

    return (
        <div>
            <Modal size="lg" animation={false} show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <strong>Compartir {props.file ? 'archivo' : ''} {props.folder ? 'carpeta' : ''}</strong>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {loading 
                        ? 
                        <InlineLoader />
                        :
                        <div className="content-steps-share">
                            
                            <div className="content-tabs d-none mb-4">
                                <Nav fill  variant="tabs" defaultActiveKey={step}>
                                    {userPermissions.can_share_files_by_link &&
                                    <Nav.Item>
                                        <Nav.Link onClick={() => setstep(1)} eventKey={1}>
                                            Enlace
                                        </Nav.Link>
                                    </Nav.Item>
                                    }
                                    {userPermissions.can_share_files_to_users && !isPublic && !onlyenlace &&
                                    <Nav.Item>
                                        <Nav.Link onClick={() => setstep(2)} eventKey={2}>
                                            Usuarios
                                        </Nav.Link>
                                    </Nav.Item>
                                    }
                                    {userPermissions.can_share_files_to_groups && !isPublic && !onlyenlace &&
                                    <Nav.Item>
                                        <Nav.Link onClick={() => setstep(3)} eventKey={3}>
                                            Roles
                                        </Nav.Link>
                                    </Nav.Item>
                                    }
                                </Nav>
                            </div>

                            {step === 1 &&
                                <div className="step-1">
                                    <div className="form-group">
                                        <h6 className="font-weight-bold mb-3">
                                            Categoría: <span className="font-weight-normal">{name}</span>
                                        </h6>
                                        <div className="input-group">
                                            <input 
                                                type="text" 
                                                readOnly
                                                disabled
                                                className="form-control" 
                                                value={window.location.origin+urlpublicpathtoFiles+uuid}
                                                placeholder="Url publica" 
                                            />
                                            <div className="input-group-append">
                                                <button 
                                                    onClick={(e) => copy(e,window.location.origin+urlpublicpathtoFiles+uuid)}
                                                    className="btn btn-primary" 
                                                    type="button"
                                                >
                                                    {(message !== "") 
                                                        ?
                                                            <i className="far fa-check-circle"></i>
                                                        :
                                                            <i className="far fa-copy"></i>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Link 
                                            className="btn font-weight-bold btn-primary" 
                                            to={urlpublicpathtoFiles+uuid}
                                        >
                                            Abrir enlace 
                                            <i className="ml-2 fa fa-external-link-alt"></i>
                                        </Link>
                                    </div>
                                    <div className="text-right">
                                        <Button variant="light" onClick={props.close}>
                                            <i className="fa fa-times mr-2"></i>Cerrar
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalShareCategory
