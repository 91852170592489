import React from 'react'

function ForgotSuccess() {
    return (
        <div className="authincation h-100">
            <div className="container h-100">
                <div className="row justify-content-center min-vh-100 align-items-center">
                    <div className="col-md-7">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <p className="text-center text-success">
                                            <i className="fa fa-check-circle fa-4x"></i>
                                        </p>
                                        <h4 className="text-center font-weight-bold h2 mb-4">
                                            Restablecimiento de contraseña enviado
                                        </h4>
                                        <p className="small">
                                            Le hemos enviado por email las instrucciones para restablecer la contreaseña, si existe una cuenta con la dirección electrónica que indicó. Debería recibirlas en breve.
                                        </p>
                                        <p className="small">
                                            Si no recibe un correo, por favor asegurese que ha introducido la dirección de correo con la cual se registró y verifique su carpeta de spam.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotSuccess
