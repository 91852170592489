import React, {useState, useEffect, useRef} from 'react'
import {Modal, Button, ProgressBar, Tab, Row, Col, Nav, OverlayTrigger, Tooltip} from 'react-bootstrap'
import axios from 'axios'
import {useSelector, useDispatch} from "react-redux"

import { FileDrop }     from 'react-file-drop';

import FileInput from './FileInput';
import TagsInput from './TagsInput';
import CategoriesSelect from './CategoriesSelect';
import { set_tags } from '../../store/dashboard/actions';
import ModalDeleteFile from './DeleteFile';

function UploadFile(props) {
    let fixedCategory   = props.fixedCategory;
    let showModalCreate = props.open;
    let handleClose     = props.close;
    let defaultcategory = props.defaultCategory;

    const settings      = useSelector(state => state.session.settings.filemanager.upload);
    const permissions   = useSelector(state => state.session.userData.permissions);

    const dispatch = useDispatch();

    let isRequiredTags = null;

    //console.log("Default category:",defaultcategory);

    if(props.private){
        isRequiredTags = settings["private-files"].tags.required;
    }else{
        isRequiredTags = settings["public-files"].tags.required;
    }

    const [showModal,       setshowModal]                   = useState(false);
    const [showModalCancelUpload, setshowModalCancelUpload] = useState(false);

    const [categoriesSelected, setcategoriesSelected]   = useState(props.defaultCategory ? props.defaultCategory : null);
    const [file, setfile]                               = useState(null);
    const [tags, settags]                               = useState([]);

    const [firstCategory, setfirstCategory]                         = useState(null);
    const [showMessageFirstCategory, setShowMessageFirstCategory]   = useState(null);

    const [lastIndex, setlastIndex]                     = useState(0);

    const [files, setfiles]                             = useState([]);
    const [progressList, setprogressList]               = useState([]);

    const [progress, setprogress]                       = useState(0);
    const [count,    setcount]                          = useState(0);

    const [errors,   seterrors]                         = useState({});
    const [errorMessage, seterrorMessage]               = useState("");

    const [sending, setsending]                         = useState(false);
    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');

    const [selectedFileId, setselectedFileId]           = useState(null);
    const [showModalCreateTag, setshowModalCreateTag]   = useState(false);
    const [tagName, setTagName]                         = useState('');
    const [sendingTag, setSendingTag]                   = useState(false);
    const [createTagserrors,   setCreateTagserrors]     = useState({});

    const inputfile                                     = useRef();

    const handleCloseModal = () => {
        if(!sending){
            handleClose();
        }else{
            setshowModalCancelUpload(true);
            //handleClose();
        }
    }

    const cancelGlobalUpload = () => {
        
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            cancelUploadToFile(file.id, file.cancelToken);
        }

        let newcount = count + 2;

        setcategoriesSelected(null);
        setsending(false);

        setprogress(0);
        setfiles([]);
        setprogressList([]);
        seterrormessage('');

        setselectedFileId(null);

        if(props.refresh){
            props.refresh();
        }

        setshowModalCancelUpload(false);
        setcount(newcount);
        handleClose();
    }

    const handleCloseModalCancelUpload = () => {
        setshowModalCancelUpload(false);
    }

    const handleCloseCreateTag = () => {
        setTagName('');
        setSendingTag(false);
        setshowModalCreateTag(false);
        setCreateTagserrors({});
    }

    const changeInputFile = (inputfiles) => {
        if(inputfiles.length > 0){
            setfile(inputfiles);
        }
    }

    useEffect(() => {
        if(props.open === true){
            if(!showModal){
                setshowModal(props.open);
                setsuccessmessage('');
                seterrormessage('');
                setprogress(0);
                setcount(0);
                setfile(null);
                setfiles([]);
                seterrors({});
                settags([]);
                setfirstCategory(null);
                setcategoriesSelected(props.defaultCategory ? props.defaultCategory : null);
            }
        }else{
            setshowModal(props.open);
        }
    });

    const createNewTag = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let url = '/filemanager/tags/add';

        let data = {
            name: tagName
        }

        setSendingTag(true);

        axios.post(
            url, 
            data        
        ).then((res) => {

            let newTagList = tags;
            console.log(tags);

            newTagList.push(res.data);

            console.log(res.data);
            dispatch(set_tags());
            handleCloseCreateTag();
            setSendingTag(false);
            //settags(newTagList);

            settagsToFile(newTagList, selectedFileId);

        }).catch((err) => {
            let error = err.response;
            console.log(err);

            if(error !== undefined){
                if(error.hasOwnProperty("data")){
                    setCreateTagserrors(error.data);
                }
            }

            setSendingTag(false);
        });
    }

    //console.log(categoriesSelected);

    const inputfileclick = () => {
        //console.log(inputfile);
        inputfile.current.click();
    }

    const inputChangeByInput = (e) => {
        //console.log("Archivos cargados:",filesd);
        let inputfiles      = e.target.files;
        //let inputfiles    = filesd;
        //let dataArray     = [];
        //dataArray.push(filesd);

        //inputfiles        = dataArray;
        //console.log(inputfiles);
        //console.log(defaultcategory);

        let uploadedFiles   =   [];
        let newProgressList =   progressList;

        if(inputfiles.length > 0){
            let url         = "";
            let lastIndex   = files.length > 0 ? files[files.length - 1].id : 0;

            for (let i = 0; i < inputfiles.length; i++) {
                const img   = inputfiles[i];
                url         = URL.createObjectURL(img);

                let newFile = {
                    id:             lastIndex+1+i,
                    file:           img,
                    url:            url,
                    category:       typeof defaultcategory === "object" ? defaultcategory : null,
                    tags:           [],
                    //progress:     0,
                    success:        false,
                    error:          false,
                    cancelToken:    axios.CancelToken.source() 
                };

                let newProgressListItem = {
                    id:             newFile.id,
                    progress:       0,
                }

                newProgressList.push(newProgressListItem);
                uploadedFiles.push(newFile);
            }

            let newListFiles = files.concat(uploadedFiles);

            console.log(newListFiles);
            console.log(newProgressList);

            setprogressList(newProgressList);
            setfiles(newListFiles);
        }
    }

    const inputChange = (filesd) => {
        //console.log("Archivos cargados:",filesd);
        //let inputfiles = e.target.files;
        let inputfiles  = filesd;
        //let dataArray   = [];
        //dataArray.push(filesd);

        //inputfiles      = dataArray;
        //console.log(inputfiles);
        //console.log(defaultcategory);

        let uploadedFiles   =   [];
        let newProgressList =   progressList;

        if(inputfiles.length > 0){
            let url = "";
            let lastIndex   = files.length > 0 ? files[files.length - 1].id : 0;

            for (let i = 0; i < inputfiles.length; i++) {
                const img   = inputfiles[i];
                url         = URL.createObjectURL(img);

                let newFile = {
                    id:             lastIndex+1+i,
                    file:           img,
                    url:            url,
                    category:       typeof defaultcategory === "object" ? defaultcategory : null,
                    tags:           [],
                    //progress:     0,
                    success:        false,
                    error:          false,
                    cancelToken:    axios.CancelToken.source()      
                };

                let newProgressListItem = {
                    id:             newFile.id,
                    progress:       0,
                }

                newProgressList.push(newProgressListItem);
                uploadedFiles.push(newFile);
            }

            let newListFiles = files.concat(uploadedFiles);

            console.log(newListFiles);
            console.log(newProgressList);

            setprogressList(newProgressList);
            setfiles(newListFiles);
        }
    }

    const setstatustofile = (status, id) => {
        let newFiles  = files;
        let thisFile  = newFiles.find(item => Number(item.id) === Number(id));
        let thisIndex = newFiles.indexOf(thisFile);

        if(status === "success"){
            newFiles[thisIndex].success = true;
        }else if(status === "error"){
            newFiles[thisIndex].error   = true;
        }

        //console.log(newFiles);
        setfiles(newFiles);
        setcount(count + 8);
    }

    const setcategoryToFile = (val, id) => {
        let newFiles  = files;
        let thisFile  = newFiles.find(item => Number(item.id) === Number(id));
        let thisIndex = newFiles.indexOf(thisFile);

        newFiles[thisIndex].category = val;

        //console.log(newFiles);

        if(firstCategory === null){
            setfirstCategory(val);
            setShowMessageFirstCategory(true);

            setTimeout(() => {
                setShowMessageFirstCategory(false);
            }, 15000);
        }else{
            setShowMessageFirstCategory(false);
        }

        setfiles(newFiles);
        setcount(count + 8);
    }

    const handleSetGlobalCategory = () => {
        console.log("seteando categoria global");
        setShowMessageFirstCategory(false);

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            setcategoryToFile(firstCategory, file.id);
        }

        let newcount = count * 0;
        setcount(newcount);
    }

    const setprogressToFile = (val, id) => {
        let newProgressList         = progressList;
        let thisFile                = progressList.find(item => Number(item.id) === Number(id));
        
        if(thisFile !== undefined){
            let thisIndex               = progressList.indexOf(thisFile);

            newProgressList[thisIndex].progress = val;
    
            console.log(newProgressList);
    
            setprogressList(newProgressList);
            setcount(count + 100);
        }
    }

    const settagsToFile = (val, id) => {
        let newFiles  = files;
        let thisFile  = newFiles.find(item => Number(item.id) === Number(id));
        let thisIndex = newFiles.indexOf(thisFile);

        newFiles[thisIndex].tags = val;

        console.log(newFiles);

        setfiles(newFiles);
        setcount(count + 100);
    }
    
    const deleteFile = (id) => {
        let newFilesResult      = [];

        let newFiles                = files;
        let thisFile                = newFiles.find(item => Number(item.id) === Number(id));

        let filesWithoutThisFile    = files.filter(item => Number(item.id) !== Number(id));
        let progressWithoutThisFile = progressList.filter(item => Number(item.id) !== Number(id));
        /*
            if(filesWithoutThisFile.length > 0){
                for (let i = 0; i < filesWithoutThisFile.length; i++) {
                    const element = filesWithoutThisFile[i];
                    let newFileFormat = {
                        id:         i,
                        file:       element.file,
                        url:        element.url,
                        category:   element.category,
                        tags:       element.tags,
                        progress:   element.progress,
                        cancelToken:element.cancelToken
                    }

                    newFilesResult.push(newFileFormat);
                }
            }
        */

        if(newFilesResult.length <= 0){
            setfirstCategory(null);
        }

        setcount(count + 5);
        setprogressList(progressWithoutThisFile);
        setfiles(filesWithoutThisFile);
    }

    const setshowModalCreateTagToFile = (id) => {
        setshowModalCreateTag(true);
        setselectedFileId(id);
    }

    const validate = () => {
        let thisErrors  = {};
        let countErrors = 0;

        for (let i = 0; i < files.length; i++) {
            const thisFile = files[i];

            if(thisFile.category === "" || thisFile.category === null){
                //countErrors++;
            }

            if(thisFile.tags.length === 0 && isRequiredTags){
                countErrors++;
            }
        }

        if(countErrors > 0){
            if(isRequiredTags){
                seterrormessage("Debe marcar etiquetas para cada archivo");
            }
            
            return false;
        }

        seterrormessage("");
        return true;
    }

    const gotoupload = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let url = '/filemanager/upload-public-file/';

        if(props.private){
            if(props.folder){
                url = '/filemanager/my-drive/folders/'+props.folder+'/files/';
            }else{
                url = '/filemanager/my-drive/files/';
            }
        } 
        
        if(props.isShare){
            if(props.parent){
                url = '/filemanager/shared-with-me/folders/'+props.parent+'/files/';
            }

            if(props.subfolder){
                url = '/filemanager/shared-with-me/folders/'+props.parent+'/descendant/'+props.subfolder+'/files/';
            }
        }

        seterrormessage("");
        let isValid = validate();

        if(isValid){

            setsending(true);
            seterrors({});

            let uploadedFilesCount = files.filter(item => item.success === true || item.error === true);
            uploadedFilesCount = uploadedFilesCount.length;

            for (let i = 0; i < files.length; i++) {
                const thisFile = files[i];

                if(thisFile.success === false && thisFile.error === false){
                    console.log("cargando archivo #"+i+1);

                    let formData = new FormData();
                    formData.append('file', thisFile.file);

                    if(thisFile.tags && Array.isArray(thisFile.tags)){
                        for (let i = 0; i < thisFile.tags.length; i++) {
                            
                            const tag = thisFile.tags[i];
                            formData.append('tags', tag.id);
                        }
                    }

                    formData.append('category', thisFile.category !== null ? thisFile.category.value : "");

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        },
                        onUploadProgress: function(progressEvent) {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            //console.log(percentCompleted);
                            setprogressToFile(percentCompleted, thisFile.id);
                            setcount(count + percentCompleted);
                        },
                        cancelToken: thisFile.cancelToken.token
                    }

                    setprogressToFile(0.2, thisFile.id);
                    axios.post(
                        url, 
                        formData, 
                        config
                    ).then((res) => {
                        //console.log(res.data);

                        uploadedFilesCount++;
                        console.log(uploadedFilesCount);

                        console.log("DATA RES");
                        console.log(res);

                        if(res.status === 201 || res.status === 200){
                            setstatustofile("success", thisFile.id);
                        }else{
                            setstatustofile("error", thisFile.id);
                        }

                        if(uploadedFilesCount === files.length){

                            if(props.private || props.folder){
                                //setsuccessmessage('¡Los archivos se han cargado correctamente en su unidad!');
                                setcategoriesSelected(null);
                            }else{
                                //setsuccessmessage('¡Los archivos se han cargado correctamente!');
                            }

                            setsending(false);
                            setselectedFileId(null);

                            if(props.refresh){
                                props.refresh();
                            }

                            //Si se cargan todos los archivos
                            //setstatustofile("success", thisFile.id);
                            //setprogress(0);

                            /*
                                setfile(null);
                                settags([]);
                                setsending(false);
                                setprogress(0);
                                setfiles([]);
                                setprogressList([]);
                                seterrormessage('');
                                
                                setTimeout(() => {
                                    setsuccessmessage('');
                                    handleClose();
                                }, 1000);
                            */
                        }
                    }).catch((err) => {
                        console.error(err);
                        let dataerr = err.response;

                        if(dataerr && dataerr.hasOwnProperty("data")){
                            setprogress(0);
                            seterrormessage('Ha ocurrido un problema al cargar los archivos');
                            seterrors(err.response.data);
                        }
                    });
                }
            }

            /*
                setsuccessmessage('');
                seterrormessage('');
            */
        }
    }

    const cancelUploadToFile = (id, token) => {
        //deleteFile(id);
        setstatustofile("error", id);
        token.cancel();
    }

    console.log(files);

    let uploadedFilesCount = files.filter(item => item.success === true || item.error === true);
    uploadedFilesCount = uploadedFilesCount.length;

    return (
        <div>

            <Modal  
                show={showModalCancelUpload} 
                backdrop={"true"} 
                onHide={() => handleCloseModalCancelUpload()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <strong>
                            ¿Desea cancelar la subida?
                        </strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="font-weight-normal mb-0">
                        La carga de los archivos se detendrá y se eliminaran de la lista.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        type="button" 
                        className="font-weight-bold" 
                        variant="primary" 
                        onClick={() => cancelGlobalUpload()}
                    >
                        <i className="fa fa-check mr-2"></i>Si, cancelar
                    </Button>
                    <Button 
                        type="button" 
                        className="font-weight-bold" 
                        variant="light" 
                        onClick={() => handleCloseModalCancelUpload()}
                    >
                        <i className="fa fa-times mr-2"></i>No, continuar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showModal} backdrop={sending ? "static" : "true"} onHide={() => handleCloseModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                        <strong>Subir archivo {((props.private || props.folder) && !props.isShare) ? 'a mi unidad' : ''}</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                        {(successmessage !== '') &&
                            <div className="alert mb-3 alert-success font-weight-bold">
                                {successmessage}
                            </div>
                        }
                        {(errormessage !== '') &&
                            <div className="alert mb-3 alert-danger font-weight-bold">
                                {errormessage}
                            </div>
                        }

                        { (progress > 0) &&
                            <ProgressBar variant="success" animated now={progress} />
                        }

                        {(showMessageFirstCategory) &&
                            <div className="alert mb-3 alert-info font-weight-bold">
                                ¿Desea copiar esta categoría a los demás archivos de la tabla? 
                                <button 
                                className="ml-2 btn btn-warning" 
                                onClick={() => handleSetGlobalCategory()}>
                                    Aceptar
                                </button>
                                <button 
                                className="ml-2 btn btn-danger" 
                                onClick={() => setShowMessageFirstCategory(false)}>
                                    Cancelar
                                </button>
                            </div>
                        }

                        <div className="w-100">
                            <div className="row">
                                <div className="col-md-12">

                                <div className="form-group">

                                    {defaultcategory !== undefined &&
                                        <h6 className="w-100 mb-4">
                                            <span className="font-weight-bold">Categoría</span>: {defaultcategory.label}
                                        </h6>
                                    }

                                    <h6 className="mb-3">Archivo(s)</h6>
                                    {(files.length === 0) &&
                                        <div className="alert text-center alert-light">
                                            <i className="fa fa-exclamation-triangle mr-2"></i>
                                            Sin archivos seleccionados
                                        </div>
                                    }

                                    {(files.length > 0) &&
                                            <div className="mb-3 w-100">
                                                <div className="content-file py-3">
                                                    {files.map((item, key) => {
                                                        let dataProgress = progressList.find(pg => pg.id === item.id);
                                                        console.log(item.id);
                                                        console.log(item.category);
                                                        if(item.success){
                                                            return (
                                                                <div className="py-3 border-top border-bottom" key={key}>
                                                                    <div className="w-100 px-2 position-relative">
                                                                        <div className="row align-items-start">
                                                                            <div className="col-10 mb-2">
                                                                                <h6 className="font-weight-bold d-flex align-items-center h6 mb-0">
                                                                                    <i className="fa fa-check-circle text-success mr-2"></i> 
                                                                                    <i className="fa fa-file mr-2"></i> 
                                                                                    {item.file.name}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }else if(item.error){
                                                            return (
                                                                <div className="py-3 border-top border-bottom" key={key}>
                                                                    <div className="w-100 px-2 position-relative">
                                                                        <div className="row align-items-start">
                                                                            <div className="col-10 mb-2">
                                                                                <h6 className="font-weight-bold d-flex align-items-center h6 mb-0">
                                                                                    <i className="fa fa-ban text-danger mr-2"></i> 
                                                                                    <i className="fa fa-file mr-2"></i> 
                                                                                    {item.file.name}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }else{
                                                            return (
                                                                <div className="py-3 border-top border-bottom" key={key}>
                                                                    <div className="w-100 px-2 position-relative">
                                                                        <div className="row align-items-start">
                                                                            <div className="col-10 mb-2">
                                                                                <h6 className="font-weight-bold h6">
                                                                                    <i className="fa fa-file mr-2"></i> {item.file.name}
                                                                                </h6>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <div className="d-flex">
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                        <Tooltip id={`tooltip-btn-dowload-file-unidad-${item.id}`}>
                                                                                            Eliminar archivo
                                                                                        </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <button onClick={() => deleteFile(item.id)} className="btn w-50 btn-unstyled text-danger">
                                                                                            <i className="fa fa-times"></i>
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row align-items-end">
                                                                            {defaultcategory === undefined &&
                                                                                <div className="col-5">
                                                                                    <h6 className="font-weight-bold">
                                                                                        Categoría
                                                                                    </h6>
                                                                                    <div style={{minWidth: "280px"}}>
                                                                                        <CategoriesSelect 
                                                                                            //isFixed={fixedCategory} 
                                                                                            value={item.category} 
                                                                                            onChange={(val) => setcategoryToFile(val, item.id)} 
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            <div className={defaultcategory !== undefined ? "col-10" : "col-5"}>
                                                                                <h6 className="font-weight-bold mb-1">
                                                                                    Etiqueta
                                                                                </h6>
                                                                                <TagsInput value={item.tags} onChange={(value) => settagsToFile(value, item.id)} />
                                                                            </div>
                                                                            <div className="col-2">
                                                                                {(permissions.can_create_tags) &&
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                        <Tooltip id={`tooltip-btn-dowload-file-unidad-${item.id}`}>
                                                                                            Crear etiqueta
                                                                                        </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <button onClick={() => setshowModalCreateTagToFile(item.id)} className="btn d-inline-flex w-50 align-items-center justify-content-center font-weight-bold btn-primary mr-1">
                                                                                            <i className="fa fa-tag"></i>
                                                                                        </button>   
                                                                                    </OverlayTrigger>      
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {sending &&
                                                                            <div className="file-sending-banner d-flex align-items-center justify-content-center">
                                                                                <div className="text-center">
                                                                                    <h6 className="text-center mb-2 font-weight-bold">
                                                                                        <i className="fa fa-spin fa-spinner mr-2"></i>Subiendo archivo
                                                                                    </h6>
                                                                                    <button onClick={() => cancelUploadToFile(item.id, item.cancelToken)} className="btn py-1 btn-danger">
                                                                                        Cancelar
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {(dataProgress.progress > 0) &&
                                                                        <div className="py-4">
                                                                            <ProgressBar variant="success" animated now={dataProgress.progress} />
                                                                        </div>
                                                                    
                                                                    }
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                    }

                                    {(!sending) &&
                                        <FileDrop
                                            onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                                            onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                                            onFrameDrop={(event) => console.log('onFrameDrop', event)}
                                            onDragOver={(event) => console.log('onDragOver', event)}
                                            onDragLeave={(event) => console.log('onDragLeave', event)}
                                            onDrop={(files, event) => inputChange(files)}
                                        >
                                            <div 
                                                //className="font-weight-bold" 
                                                onClick={() => inputfileclick()}  
                                                style={{borderRadius: "6px",border: "2px dotted #eee", cursor: "pointer"}} 
                                                className="font-weight-normal py-4 border-primary text-dark text-center w-100"
                                            >
                                                <i className="fa fa-cloud-upload-alt mr-2"></i>
                                                Arrastra tu(s) archivo(s) aquí o haga clic para seleccionarlo(s).
                                            </div>
                                        </FileDrop>
                                    }

                                    <button 
                                        onClick={() => inputfileclick()} 
                                        className="btn btn-block w-100 btn-primary position-relative d-none"
                                    >
                                        <input 
                                            onChange={(e) => inputChangeByInput(e)} 
                                            multiple
                                            type="file" 
                                            id="inputfileActionBox" 
                                            className="input-file-action" 
                                            ref={inputfile} 
                                        />
                                        Añadir archivo(s)
                                    </button>
                                </div>

                                </div>

                                <div className="col-md-12 d-none">
                                    <div className="form-group">
                                        <label htmlFor="">Categoría</label>

                                        {!fixedCategory &&
                                            <CategoriesSelect isFixed={fixedCategory} value={categoriesSelected} onChange={(val) => setcategoriesSelected(val)} />
                                        }

                                        {fixedCategory &&
                                            <div>
                                                <div className="d-none">
                                                    <CategoriesSelect isFixed={fixedCategory} value={categoriesSelected} onChange={(val) => setcategoriesSelected(val)} />
                                                </div>
                                                {categoriesSelected.hasOwnProperty('label') &&
                                                    <p className="mb-0 font-weight-bold">
                                                        {categoriesSelected.label}
                                                    </p>
                                                }
                                            </div>
                                        }
                                        {errors.hasOwnProperty('category') && Array.isArray(errors.category) &&
                                            <div className="help-block text-danger font-weight-bold">
                                                <p className="mb-0 py-2">
                                                    <i className="fa fa-exclamation-triangle mr-2"></i>
                                                    {errors.category[0]}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-md-12 d-none">
                                <div className="form-group">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label htmlFor="inputtag">Etiquetas</label>
                                        {(permissions.can_create_tags) &&
                                            <div className="text-right">
                                                <button onClick={() => setshowModalCreateTag(true)} className="btn px-3 font-weight-bold py-1 btn-sm btn-secondary">
                                                    Crear etiqueta
                                                </button>   
                                            </div>
                                        }
                                    </div>
                                    <TagsInput value={tags} onChange={(value) => settags(value)} />
                                    {errors.hasOwnProperty('tags') && Array.isArray(errors.tags) &&
                                        <div className="help-block text-danger font-weight-bold">
                                            <p className="mb-0 py-2">
                                                <i className="fa fa-exclamation-triangle mr-2"></i>
                                                {errors.tags[0]}
                                            </p>
                                        </div>
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {(!sending) &&
                            <Button disabled={
                                sending || 
                                (
                                files.length === 0 ||
                                files.length > 0 && files.length === uploadedFilesCount
                                //|| (Array.isArray(tags) && tags.length === 0 && isValid) 
                                //|| (categoriesSelected === null && !props.private && !props.isShare)
                                )
                                } onClick={(e) => gotoupload(e)} type="button" className="font-weight-bold" variant="primary">
                                {(sending) ? <span><i className="fa fa-spin fa-spinner mr-2"></i>Subiendo archivo (s)</span> : <span><i className="fa fa-send mr-2"></i>Subir archivo (s)</span>}
                            </Button>
                        }
                        <Button type="button" className="font-weight-bold" variant="light" onClick={() => handleCloseModal()}>
                            <i className="fa fa-times mr-2"></i>Cerrar
                        </Button>
                    </Modal.Footer>
                
                </Modal>
        
            <Modal show={showModalCreateTag} onHide={handleCloseCreateTag}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Crear nueva etiqueta
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {(typeof createTagserrors === "object" && createTagserrors.hasOwnProperty("non_field_errors")) &&
                            <div className="alert alert-danger small font-weight-bold">
                                {createTagserrors.non_field_errors}
                            </div>
                        }
                        <div className="form-group">
                            <label htmlFor="tag-name">
                                Nombre de la etiqueta
                            </label>
                            <input 
                                value={tagName}
                                onChange={(e) => setTagName(e.target.value)}
                                id="tag-name"
                                type="text" 
                                className="form-control"
                                placeholder="Nombre de la etiqueta" 
                            />
                        </div>
                </Modal.Body>
                <Modal.Footer>
                        <Button 
                            disabled={sending || (tagName === null || tagName === '')} 
                            onClick={(e) => createNewTag(e)} 
                            type="button" 
                            className="font-weight-bold" 
                            variant="secondary"
                        >
                            {(sendingTag) ? <span><i className="fa fa-spin fa-spinner mr-2"></i>Cargando</span> : <span>Añadir etiqueta</span>}
                        </Button>
                        <Button type="button" className="font-weight-bold" variant="light" onClick={handleCloseCreateTag}>
                            <i className="fa fa-times mr-2"></i>Cancelar
                        </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UploadFile
