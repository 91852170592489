import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'

import avatar from '../../../../static/images/profile/profile.png';
import CardSearchTop from '../../../helpers/CardSearchTop';
import CardSearchTopMobile from '../../../helpers/CardSearchTopMobile';

function Header() {

    const data = useSelector(state => state.session.userData);
    const userPermissions = data.permissions;

    let photo = data.photo;

    return (
        <header>
            <div className="header">
                <CardSearchTopMobile />

                <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                    <div className="header-left">
                        <div className="dashboard_bar">
                            Dashboard
                        </div>
                    </div>
                    <ul className="navbar-nav header-right">
                        <li className="nav-item dropdown notification_dropdown position-relative">  
                            <CardSearchTop />
                        </li>

                        <li className="nav-item d-flex d-lg-none dropdown">
                            <button id="toggle-search-mobile" className="nav-link btn py-3 btn-unstyled">
                                <i style={{fontSize: "22px"}} className="fa fa-search"></i>
                            </button>
                        </li>

                        <Dropdown className="nav-item header-profile">
                            <Dropdown.Toggle as="a" className="nav-link px-3">
                                <img src={(photo === null) ? avatar : photo} width={20} alt="" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-right">
                                <Link to="/perfil" className="dropdown-item ai-icon">
                                    <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                                    <span className="ml-2">Perfil </span>
                                </Link>
                                {userPermissions.has_drive_access &&
                                    <Link to="/unidad" className="dropdown-item ai-icon">
                                        <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg>
                                        <span className="ml-2">Mi unidad </span>
                                    </Link>
                                }
                                <Link to="/logout" className="dropdown-item ai-icon">
                                    <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1={21} y1={12} x2={9} y2={12} /></svg>
                                    <span className="ml-2">Cerrar sesión </span>
                                </Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ul>
                    </div>
                </nav>
                </div>
            </div>
        </header>
    )
}

export default Header
