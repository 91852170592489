import React from 'react'
import moment from "moment"
import {OverlayTrigger, Tooltip} from "react-bootstrap"

function NameComponent({item, id, name, status, status_name, selectItem, showModalStatus, showBtn=false, titleclass = ""}) {

    const changeStatus = (item) => {
        selectItem(item);
        showModalStatus(true);
    }

    let nameformat = name.split(" ");

    return (
        <div className="content-name-file">

            <div className='d-flex align-items-center justify-content-start flex-wrap-reverse'>
                {(status && status === "N") 
                    ? 
                    <span className="badge badge-sm small badge-success font-weight-bold mr-2 mb-2">
                        {status_name}
                    </span> 
                    : 
                    ""
                }

                {(status && status === "D") 
                    ? 
                    <span className="badge badge-sm small badge-danger font-weight-bold mr-2 mb-2">
                        {status_name}
                    </span> 
                    : 
                    ""
                }

                <div className='small d-flex align-items-center flex-wrap mb-2'>
                    <span>
                        {moment(item.date_created).format("DD/MM/YYYY HH:mm")}
                    </span>
                    <span className='mx-1'>
                        -
                    </span>
                    <span>
                        {item.size}
                    </span>
                </div>
            </div>

            {(status && status === "D") 
            ?
                <h4 className={`${titleclass !== "" ? titleclass : 'h5 text-black mb-1'} wspace-no`}>
                    <div className="content-file-title">
                        {nameformat.map((item, key) => {
                            if(item.length < 30){
                                return (
                                    <span className={`title-wrap`} key={key}>
                                        {item}
                                    </span>
                                )
                            }else{
                                let separarLetras = item.match(/.{1,30}(.$)?/g);
                                //console.log(separarLetras);

                                return separarLetras.map((subitem, subkey) => {
                                    return (
                                        <span className={`title-wrap ${(item.length > 29) ? "letter-wrap" : ""}`} key={subkey}>
                                            {subitem}
                                        </span>
                                    )
                                })
                            }
                        })}
                        {(showBtn) &&
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id={`change-status-file-id-${id}`}>
                                        Editar.
                                    </Tooltip>
                                }
                                >
                                    <button onClick={() => changeStatus(item)} className="btn btn-sm py-0 ml-2">
                                        <i className="fa fa-ellipsis-v"></i>
                                    </button>
                            </OverlayTrigger>
                        }
                    </div> 
                </h4>
            :
                <h4 className={`${titleclass !== "" ? titleclass : 'h5 text-black mb-1'} wspace-no`}>
                    <div className="content-file-title">
                        {nameformat.map((item, key) => {
                            if(item.length < 30){
                                return (
                                    <span className={`title-wrap`} key={key}>
                                        {item}
                                    </span>
                                )
                            }else{
                                let separarLetras = item.match(/.{1,30}(.$)?/g);
                                //(separarLetras);

                                return separarLetras.map((subitem, subkey) => {
                                    return (
                                        <span className={`title-wrap ${(item.length > 29) ? "letter-wrap" : ""}`} key={subkey}>
                                            {subitem}
                                        </span>
                                    )
                                })
                            }
                        })} 
                    
                        {(showBtn) &&
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id={`change-status-file-id-${id}`}>
                                        Editar.
                                    </Tooltip>
                                }
                                >
                                    <button onClick={() => changeStatus(item)} className="btn btn-sm py-0 ml-2">
                                        <i className="fa fa-ellipsis-v"></i>
                                    </button>
                            </OverlayTrigger>
                        }
                    </div>
                </h4>
            }
        </div>
    )
}

export default NameComponent
