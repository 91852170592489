import React, {useState} from 'react'
import {Link, withRouter} from "react-router-dom"
import axios from "axios"

function ForgotPassword(props) {
    
    const [errorMessage,    seterrorMessage]        = useState("");
    const [errors,          seterrors]              = useState({});
    
    const [email,   setemail]   = useState('');
    const [sending, setsending] = useState(false);

    const validate = () => {
        let counterrors = 0;
        let errorslist = {};

        if(email === ''){
            errorslist.email = 'Debe ingresar un correo electrónico';
            counterrors++;
        }

        if(counterrors > 0){
            seterrors(errorslist);
            return false;
        }

        return true;
    }

    const goForgot = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let url = "/accounts/password-reset/";
        seterrors({});
        seterrorMessage("");
        let isValid = validate();

        if(isValid){
            setsending(true);
            axios({
                method: "post",
                url,
                data: {
                    email
                }
            }).then((res) => {
                console.log(res);
                props.history.push("/accounts/password-reset/send/");
                setsending(false);
            }).catch((err) => {
                console.error(err);
                setsending(false);
                if(err.response){
                    if(err.response.data.hasOwnProperty("email")){
                        seterrors(err.response.data);
                    }
                }
            });
        }
    }

    return (
        <div className="authincation h-100">
            <div className="container h-100">
                <div className="row justify-content-center min-vh-100 align-items-center">
                    <div className="col-md-6">
                        {(errorMessage !== "") &&
                            <div className="alert alert-danger">
                                <p className="mb-0 font-weight-normal">
                                    {errorMessage}
                                </p>
                            </div>
                        }
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <h4 className="text-center font-weight-bold h2 mb-4">
                                            Restablecer contraseña
                                        </h4>
                                        <p className='small'>
                                            ¿Ha olvidado su clave? introduzca su dirección de correo a continuación y le enviaremos las instrucciones para establecer una nueva.
                                        </p>
                                        <form action="" onSubmit={(e) => goForgot(e)}>
                                            <div className="form-group">
                                                <label><strong>Email</strong></label>
                                                <input value={email} onChange={(e) => setemail(e.target.value)} type="email" className="form-control" placeholder="hello@example.com" />
                                                {(errors && errors !== null && errors.hasOwnProperty('email')) &&
                                                    <div>
                                                        <p className="mb-0 small text-danger">{errors.email}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="text-center pt-3">
                                                <button disabled={sending} type="submit" className="btn btn-primary btn-block">
                                                    {(!sending) 
                                                    ? <span>Restablecer mi contraseña</span> 
                                                    : <span><i className="fa fa-spin fa-spinner"></i></span>
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                        <div className="form-group text-center pt-3">
                                            o volver a 
                                            <Link to="/login" className="ml-2">
                                                iniciar sesion
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ForgotPassword);
