import React, {useState, useEffect, Fragment} from 'react'
import axios from 'axios'
import {useSelector, useDispatch} from 'react-redux'
import {withRouter, Link} from 'react-router-dom'
import { FileIcon, defaultStyles } from 'react-file-icon';
import {OverlayTrigger, Tooltip} from "react-bootstrap"

import dummyImg from '../../../../static/images/avatar/1.jpg';
import folderImg from '../../../../static/images/folder.png';

import {Dropdown} from "react-bootstrap"
import categoryImg from '../../../../static/images/category2.png';

import UploadFile from '../../../helpers/UploadFile';
import { downloadURI, formatUrl, downloadFileFromUrl, downloadFunction } from '../../../utils/urls';
import ModalShowFile from '../../../helpers/ShowFile';
import Loader from '../../../helpers/Loader';
import InlineLoader from '../../../helpers/InlineLoader';
import BtnAddToFavourities from '../../../helpers/BtnAddToFavourities';
import ModalUpdateStatus from '../../../helpers/Files/ModalUpdateStatus';
import NameComponent from '../../../helpers/Files/NameComponent';
import ModalDeleteFile from '../../../helpers/DeleteFile';
import ModalRenameFile from '../../../helpers/RenameFile';
import ModalShareFile from '../../../helpers/ModalShareFile';
import ModalCreateSubCategory from '../../../helpers/CreateSubCategory';
import ModalDeleteSubCategory from '../../../helpers/DeleteSubCategory';
import ModalShareCategory from '../../../helpers/ModalShareCategory';
import DataGroupButtons from '../../../helpers/DataGroupButtons';
import ModalCopyFileInDirectory from '../../../helpers/CopyFileInDirectory';
import ModalCopyFileNow from '../../../helpers/CopyFileNow';

function Category(props) {

    let propid = props.match.params.id;
    const categories = useSelector(state => state.dashboard.categories);
    const session = useSelector(state => state.session);
    const userPermissions = session.userData.permissions;

    let category = categories.find(cat => Number(cat.id) === Number(propid));
    const [subcategory, setsubcategory]   = useState(null);
    const [showcategory, setshowcategory] = useState(null);

    const [id,      setId]        = useState(propid);
    const [loading, setloading]   = useState(true);

    const [loadingData, setloadingData]   = useState(true);
    const [list, setlist] = useState(null);

    const [search,  setsearch]    = useState(true);
    const [data,    setdata]      = useState(null);
    
    const [fileSelected,   setfileSelected]       = useState(null);
    const [categorySelected, setcategorySelected] = useState(null);
    const [showModalStatus, setShowModalStatus] = useState(false);
    const [showModalEditCategory, setShowModalEditCategory] = useState(false);

    //multiple
    const [selecItems, setselecItems]       = useState(false);
    const [selectedItems, setselectedItems] = useState({files: [], folders: []});

    const [showModalCopyMultipleFile, setShowModalCopyMultipleFile] = useState(false);

    //multipleSelect

    const cancelMultipleSelect = () => {
      setselectedItems({files: [], folders: []});
      setselecItems(false);
    }

    const changeSelectAll = (e) => {
      if(e.target.checked){
        setselectedItems({
          files: list.files,
          folders: []
        });
      }else{
        setselectedItems({files: [], folders: []});
      }
    }

    const addOrRemoveItem = (item, itemType) => {
      console.log("change");
      let actualSelected = {...selectedItems};
      
      if(actualSelected[itemType].includes(item)){
        actualSelected[itemType] = actualSelected[itemType].filter(selecteditem => selecteditem !== item);
      }else{
        actualSelected[itemType].push(item);
      }
    
      // console.log(actualSelected);
      setselectedItems(actualSelected);
    }

    const MultipleCheckBoxItem = ({ type = "all", item = null, itemType = "" }) => {
      if(type === "all"){
        return  <th width="10px" style={{width: "10px"}}>
                  <div className='table-checkbox'>
                    <input 
                      onChange={(e) => changeSelectAll(e)} 
                      type="checkbox" 
                      checked={list !== null && (selectedItems.files.length === list.files.length)}
                    />
                  </div>
                </th>
      }else if(type === "folder" || type === "file"){
        return  <td width="10px" style={{width: "10px"}}>
                  <div className='table-checkbox'>
                    <input 
                      onChange={(e) => addOrRemoveItem(item, itemType)} 
                      type="checkbox" 
                      checked={selectedItems[itemType].includes(item)}
                    />
                  </div>
                </td>
      }else{
        return <td width="10px" style={{width: "10px"}}>&nbsp;</td>
      }
    }

    const dispatch        = useDispatch();
    const downloadList    = useSelector(state => state.dashboard.download_list);
    console.log("Lista de descargas:",downloadList);

    const [showModalCreate, setShowModalCreate]                       = useState(false);
    const handleClose = () => setShowModalCreate(false);
    const handleShow = () => setShowModalCreate(true);

    const [showModalCreateSubCategory, setshowModalCreateSubCategory] = useState(false);
    const handleCloseModalCreateSubCategory = () => setshowModalCreateSubCategory(false);
    const handleShowModalCreateSubCategory = () => setshowModalCreateSubCategory(true);

    const [showModalRenameSubCategory, setshowModalRenameSubCategory] = useState(false);
    const handleCloseModalRenameSubCategory = () => setshowModalRenameSubCategory(false);
    const handleShowModalRenameSubCategory = () => setshowModalRenameSubCategory(true);

    const [showModalDeleteSubCategory, setshowModalDeleteSubCategory] = useState(false);
    const handleCloseModalDeleteSubCategory = () => setshowModalDeleteSubCategory(false);
    const handleShowModalDeleteSubCategory = () => setshowModalDeleteSubCategory(true);

    const [showModalShareSubCategory, setshowModalShareSubCategory] = useState(false);
    const handleCloseModalShareSubCategory = () => setshowModalShareSubCategory(false);
    const handleShowModalShareSubCategory  = () => setshowModalShareSubCategory(true);

    const RenameSubCategory = (item) => {
      setcategorySelected(item);
      handleShowModalRenameSubCategory();
    }

    const DeleteSubCategory = (item) => {
      setcategorySelected(item);
      handleShowModalDeleteSubCategory();
    }

    const ShareSubCategory = (item) => {
      setcategorySelected(item);
      handleShowModalShareSubCategory();
    }


    //copy
    const [showModalCopyFileNow, setShowModalCopyFileNow] = useState(false);
    const handleCloseModalCopyFileNow = () => {
      setShowModalCopyFileNow(false);
    }

    const [showModalCopyFileInDirectory, setShowModalCopyFileInDirectory] = useState(false);
    const handleCloseModalCopyFileInDirectory = () => {
      setShowModalCopyFileInDirectory(false);
    }
    

    //share
    const [showModalShareFile, setShowModalShareFile]                 = useState(false);
    const handleCloseModalShareFile = () => {
      setShowModalShareFile(false);
    }
    const handleShowModalShareFile = () => {
      setShowModalShareFile(true)
    };

    const shareFileNow = (file) => {
      setfileSelected(file);
      handleShowModalShareFile();
    }

    //rename
    const [showModalRenameFile, setShowModalRenameFile] = useState(false);
    const handleCloseModalRenameFile = () => {
      setShowModalRenameFile(false);
    }
    const handleShowModalRenameFile = () => {
      setShowModalRenameFile(true)
    };

    const RenameFileNow = (file) => {
      setfileSelected(file);
      handleShowModalRenameFile();
    }

    //show
    const [showModalShowFile, setShowModalShowFile] = useState(false);
    const handleCloseModalShowFile = () => {
      setShowModalShowFile(false);
    }
    const handleShowModalShowFile = () => {
      setShowModalShowFile(true)
    };

    //delete
    const [showModalDeleteFile, setShowModalDeleteFile] = useState(false);
    const handleCloseModalDeleteFile = () => {
      setShowModalDeleteFile(false);
    }
    const handleShowModalDeleteFile = () => {
      setShowModalDeleteFile(true)
    };

    const deleteFileNow = (file) => {
      setfileSelected(file);
      handleShowModalDeleteFile();
    }

    const showModal = (item, typeModal, typeItem) => {

      if(typeItem === "category"){
        setcategorySelected(item);

        switch (typeModal) {
          case "rename":
            setshowModalRenameSubCategory(true);
            break;

          case "move":
            //setShowModalMoveFile(true);
            break;

          case "share":
            setshowModalShareSubCategory(true);
            break;

          case "delete":
            setshowModalDeleteSubCategory(true);
            break;
        
          default:
            break;
        }
      }else if(typeItem === "file"){
        setfileSelected(item);

        switch (typeModal) {
          case "rename":
            setShowModalRenameFile(true);
            break;

          case "copy":
            setShowModalCopyFileNow(true);
            break;

          case "copyInDirectory":
            setShowModalCopyFileInDirectory(true);
            break;

          case "move":
            //setShowModalMoveFile(true);
            break;

          case "share":
            setShowModalShareFile(true);
            break;

          case "delete":
            setShowModalDeleteFile(true);
            break;

          case "download":
            downloadFileFromUrl(null, formatUrl(item.file), item.name, downloadFunction, item, dispatch, downloadList)
            break;

          case "show":
            setShowModalShowFile(true);
            break;

          case "editStatus":
            setShowModalStatus(true);
          break;

          case "editCategory":
            setShowModalEditCategory(true);
          break;
        
          default:
            break;
        }
      }
    }

    const getdata = () => {
        let url = '/filemanager/list-public-files-by-category/'+id+'/';

        axios({
            method: 'GET',
            url
        }).then(async (res) => {
            console.log('DATA CATEGORY',res.data);
            await setlist(null);

            setlist(res.data);
            setloadingData(false);
        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        if(loadingData){
          if(search){
            setsearch(false);
            getdata();
        }
        }else{
          if(Number(propid) !== Number(id)){
            setselecItems(false);
            setselectedItems({files: [], folders: []});
            setShowModalCopyMultipleFile(false);

            setloadingData(true);
            setsearch(true);
            setId(propid);
          }
        }
    });

    const refresh = () => {
        setShowModalCopyFileNow(false);

        setselecItems(false);
        setselectedItems({files: [], folders: []});
        setShowModalCopyMultipleFile(false);

        setId(propid);
        getdata();
    }

    const showFileNow = (file) => {
      setfileSelected(file);
      handleShowModalShowFile();
    }

    /*
    let objectThisCategory = {
      id:   propid,
      name: "" 
    }
    */

    return (
        <div className="">
        {(!loadingData && list !== null) 
          ?
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="##">
                  Categorías
                </a>
              </li>
              {list.breadcrumbs && list.breadcrumbs.map((item, key) => {
                return (
                  <li key={key} className="breadcrumb-item">
                    <Link to={"/categoria/"+item.id}>
                      {item.name}
                    </Link>
                  </li>
                )
              })}
            </ol>
          </div>
          :
          <div>
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="##">
                    Categorías
                  </a>
                </li>
              </ol>
            </div>
          </div>
        }

        <div className="row mb-5 align-items-center">
          <div className="col-lg-12">
            <div className="card m-0 ">
              <div className="card-body py-3 py-md-2">
                <div className="row align-items-center">

                  <div className="col-md-5 col-6 mb-md-0">
                    <div className="media align-items-end">
                      <div className="media-body ml-1">
                        <h3 className="mb-0 text-black font-w600 fs-20">Categoría</h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7 col-6">
                    <div className="row align-items-center">
                      <div className="col col-md-12 text-right">

                      {selecItems &&
                        <div className='d-inline-flex mr-2'>
                          
                          {(selectedItems.files.length > 0 || selectedItems.folders.length > 0) &&
                            <Dropdown flip="true">
                              <Dropdown.Toggle 
                                className='rounded' 
                                variant="primary" 
                                id="multiple-seleccion-menu"
                                disabled={selectedItems.length <= 0}
                              >
                                {
                                  // <i className="fa fa-cog pr-2"></i>
                                }
                                {selectedItems.files.length+selectedItems.folders.length} seleccionado(s)
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setShowModalCopyMultipleFile(true)}>
                                  Copiar elementos
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          }
                            
                          
                        </div>
                      }
            
                      {selecItems 
                      ?
                        <button onClick={() => cancelMultipleSelect()} className='btn my-1 btn-primary rounded btn-sm px-4 mr-2'>
                          Cancelar
                        </button>
                      :
                        <button onClick={() => setselecItems(true)} className='btn my-1 btn-primary rounded btn-sm px-4 mr-2'>
                          Seleccionar elementos
                        </button>
                      }


                        { list !== null && list.can_create_subcategory &&
                          <button onClick={() => handleShowModalCreateSubCategory()} className="btn m-0 font-weight-bold btn-outline-warning rounded btn-sm px-4 mr-2" id="btn_subir_interno">
                            Crear subcategoría
                          </button>
                        }

                        {
                        /*2 === 2 &&
                          <button onClick={() => handleShow()} className="btn m-0 font-weight-bold btn-outline-info rounded btn-sm px-4" id="btn_subir_interno">
                            compartir
                          </button>
                        */
                        }

                        {userPermissions.can_create_public_files && list !== null && list.can_upload &&
                          <button onClick={() => handleShow()} className="btn m-0 font-weight-bold btn-outline-primary rounded btn-sm px-4" id="btn_subir_interno">
                            Subir
                          </button>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7 d-none text-md-right">
                    <div className="basic-dropdown float-right">
                      <div className="dropdown">
                        <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                          Sub-categoría
                        </button>
                        <div className="dropdown-menu" x-placement="bottom-start" style={{position: 'absolute', transform: 'translate3d(0px, 56px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
                          <a className="dropdown-item" href="subcategoria1.html">Institucional</a>
                          <a className="dropdown-item" href="#">Carros</a>
                          <a className="dropdown-item" href="#">Motos</a>
                          <a className="dropdown-item" href="#">Camiones</a>
                          <a className="dropdown-item" href="#">Buses</a>
                          <a className="dropdown-item" href="#">Industrial</a>
                        </div>
                      </div>
                    </div>
                    <a href="##" className="btn btn-outline-primary rounded btn-sm px-4" id="btn_subir_interno">Subir</a>
                  </div>

                </div>							
              </div>
            </div>
          </div>
        </div>
        
        {(loadingData)
        ? 
          <div>
              <InlineLoader />
          </div>
        :
          <div className="row mb-5">
            {(list !== null ) &&
                <div className="col-xl-12">	
                  
                      <div className="table-responsive table-hover fs-14">
                          <table className="table display mb-4 dataTablesCard fs-14 dataTable no-footer" id="example5" role="grid" aria-describedby="example5_info">
                            <thead>
                              <tr role="row">
                              {selecItems &&
                                <MultipleCheckBoxItem type="all" />
                              }
                              <th style={{width: 'auto'}} 
                                className="sorting_asc" 
                                tabIndex={0} 
                                aria-controls="example5" 
                                rowSpan={1} 
                                colSpan={1} 
                                aria-sort="ascending" 
                                aria-label="Archivo: activate to sort column descending"
                              >
                                Nombre
                              </th>
                              <th 
                                className="text-center sorting" 
                                tabIndex={0} 
                                aria-controls="example5" 
                                rowSpan={1} 
                                colSpan={1} 
                                aria-label="Acción: activate to sort column ascending" 
                                style={{width: '568px'}}>
                                  Acción
                              </th>
                              </tr>
                            </thead>
                            <tbody>
                              {(list.categories && Array.isArray(list.categories)) &&
                                <Fragment>
                                  {(list.categories.length > 0 && list.categories.map((item, key) => {
                                    return (
                                      <tr key={key}>
                                        {selecItems &&
                                          <MultipleCheckBoxItem type="empty" />
                                        }
                                        <td>
                                          <Link 
                                            to={"/categoria/"+item.id}
                                            style={{width: 'auto'}} 
                                            className="media align-items-center"
                                          >
                                            <img 
                                              className="img-fluid rounded mr-3 d-xl-inline-block" 
                                              width={70} 
                                              src={categoryImg} 
                                              alt="Carpeta" 
                                            />
                                            <div className="media-body">
                                              <h4 className="text-black font-w600 mb-1 wspace-no">
                                                {item.name}
                                              </h4>
                                            </div>
                                          </Link>
                                        </td>
                                        
                                        <td className="text-center text-dark">
                                            <span>
                                              Contiene {item.total_files_and_subcategories.total_files} archivos, {item.total_files_and_subcategories.total_subcategories} subcategorias
                                            </span>
                                            <DataGroupButtons 
                                              page="category"
                                              type="category"
                                              userPermissions={userPermissions} 
                                              id={`btnc-category-unidad-${item.id}`} 
                                              item={item}
                                              permissions={item.permissions}
                                              //setFolder={setfolderSelected}
                                              //setFile={setfileSelected}
                                              showModal={showModal}
                                            /> 
                                        </td>
                                      </tr>
                                    )
                                  }))}
                                </Fragment>
                              }

                              {(list.files && Array.isArray(list.files)) &&
                                <Fragment>
                                  {(list.files.length > 0 && list.files.map((item, key) => {
                                    let filename = item.name.split('.');
                                    let formattext = filename[filename.length - 1].toLowerCase();

                                    return (
                                      <tr key={key} className={selectedItems["files"].includes(item) ? " selected " : ""}>
                                        {selecItems &&
                                          <MultipleCheckBoxItem type="file" item={item} itemType="files" />
                                        }
                                        <td>
                                          <div 
                                            style={{width: 'auto'}} 
                                            className="media align-items-center"
                                          >
                                            <div className={item.is_symbolic_link ? "is-symbolic-item" : ""}>
                                              {(item.thumbnail !== null && item.thumbnail !== '') 
                                              ?
                                                <img 
                                                  className="img-fluid rounded mr-3 d-xl-inline-block" 
                                                  width={70} 
                                                  src={formatUrl(item.thumbnail)} 
                                                  alt="Carpeta" 
                                                />
                                              :
                                                <div style={{ width: "70px", marginRight: "15px" }}>
                                                  <div style={{ width: "70px"}}></div>
                                                  <FileIcon radius={12} extension={formattext} {...defaultStyles[formattext]} />
                                                </div>
                                              }
                                            </div>
                                            <div className="media-body">
                                              <NameComponent
                                                item={item} 
                                                id={item.id} 
                                                name={item.name} 
                                                status={item.status} 
                                                status_name={item.status_name} 
                                                selectItem={setfileSelected}
                                                showModalStatus={() => setShowModalStatus(true)}
                                                //showBtn={item.permissions.can_change_status}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-start justify-content-center">
                                            
                                            <DataGroupButtons 
                                              page="category"
                                              type="file"
                                              userPermissions={userPermissions} 
                                              id={`btn-category-unidad-${item.id}`} 
                                              item={item}
                                              permissions={item.permissions}
                                              //setFolder={setfolderSelected}
                                              //setFile={setfileSelected}
                                              showModal={showModal}
                                            />  

                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  }))}    
                                </Fragment>
                              }
                            </tbody>
                          </table>
                      
                      </div>
                    
                </div>
              }
          </div>
        }


        {(list !== null) &&
          <Fragment>
            <ModalUpdateStatus refresh={refresh}  type="status"  file={fileSelected} show={showModalStatus} close={() => setShowModalStatus(false)} />
            <ModalUpdateStatus refresh={refresh}  type="category"  file={fileSelected} show={showModalEditCategory} close={() => setShowModalEditCategory(false)} />

            {userPermissions.can_create_public_files &&
              <UploadFile 
                refresh={refresh}
                defaultCategory={
                  {
                    label: list.breadcrumbs[list.breadcrumbs.length - 1].name, 
                    value: list.breadcrumbs[list.breadcrumbs.length - 1].id
                  }
                } 
                open={showModalCreate} 
                close={handleClose} 
                fixedCategory={true}
              />
            }

            {list !== null && list.can_upload &&
              <div>
                <ModalCreateSubCategory refresh={refresh} categoryId={propid} show={showModalCreateSubCategory} close={handleCloseModalCreateSubCategory} />
                <ModalCreateSubCategory rename subcat={categorySelected} refresh={refresh} categoryId={propid} show={showModalRenameSubCategory} close={handleCloseModalRenameSubCategory} />
                <ModalDeleteSubCategory refresh={refresh} category={categorySelected} categoryId={propid} show={showModalDeleteSubCategory} close={handleCloseModalDeleteSubCategory} />
             
              </div>
            }

            {userPermissions.can_share_files &&
              <div>
                <ModalShareFile   ispublic  refresh={refresh}      file={fileSelected}    show={showModalShareFile}      close={handleCloseModalShareFile} />
                <ModalShareCategory refresh={refresh} category={categorySelected} show={showModalShareSubCategory} close={handleCloseModalShareSubCategory} />
              </div>
            }
          </Fragment>
        }
        
        <ModalCopyFileInDirectory   ispublic  refresh={refresh}   file={fileSelected} folder={null} path={id} show={showModalCopyFileInDirectory} close={() => handleCloseModalCopyFileInDirectory()} />        
        <ModalCopyFileNow           ispublic  refresh={refresh}   folder={id} file={fileSelected} show={showModalCopyFileNow}     close={handleCloseModalCopyFileNow} />
        <ModalCopyFileInDirectory   ispublic  refresh={refresh}   folder={null} multiple items={selectedItems} path={id} show={showModalCopyMultipleFile} close={() => setShowModalCopyMultipleFile(false)} />
      
        <ModalRenameFile    ispublic   refresh={refresh}     file={fileSelected}    show={showModalRenameFile}    close={handleCloseModalRenameFile} />
        <ModalShowFile      refresh={refresh}                file={fileSelected}    show={showModalShowFile}      close={handleCloseModalShowFile} />
        <ModalDeleteFile    ispublic   refresh={refresh}    file={fileSelected}    show={showModalDeleteFile}     close={handleCloseModalDeleteFile} />
      </div>
    )
}

export default withRouter(Category);
