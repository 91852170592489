import React, {useState, useEffect} from 'react'
import {
    Modal,
    Button,
    ProgressBar,
    Tab,
    Tabs,
    Sonnet 
} from 'react-bootstrap'
import axios from 'axios'
import {useSelector} from "react-redux"
import InlineLoader from './InlineLoader';
import CategoriesSelect from './CategoriesSelect';

function ModalCopyFolderInDirectory(props) {

    let urlthree = "/filemanager/my-drive/get-three-folders/";

    const [loading, setloading]                         = useState(true);
    const [search, setsearch]                           = useState(true);
    const [three, setthree]                             = useState(null);
    const [newThree, setnewThree]                       = useState(null);
    const [navigationFolders, setnavigationFolders]     = useState([]);

    const [showModal,   setshowModal]                   = useState(false);

    const [sending, setsending]                         = useState(false);
    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');
    const [progress, setprogress]                       = useState(0);
    const [count,    setcount]                          = useState(0);

    const [categorySelected, setcategorySelected]       = useState(null);
    const [typeCopy, settypeCopy]                       = useState("1");

    const [item,   setitem]                             = useState(null);
    const [folder, setfolder]                           = useState(null);
    const [items, setitems]                             = useState(null);

    const [errors,   seterrors]                         = useState({});

    const [name, setname]                               = useState((props.item) ? props.item.name : '');
    const [format, setformat]                           = useState('');
    
    const [status, setstatus]                           = useState(null);

    const statusList = useSelector(state => state.dashboard.status);

    useEffect(() => {
        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setnavigationFolders([]);
                setthree(null);
                setsearch(true);
                setloading(true);
                setsuccessmessage('');
                seterrormessage('');
                setprogress(0);
                setcount(0);
            }

            if(loading){
                if(search){
                    getData();
                }
            }else{
                if(search){
                    setsearch(false);
                    console.log("cambiando");
                    
                    if(props.item !== item && props.item !== null && props.item !== undefined){
                        console.log('Actualizando archivo');
                        setsending(false);
                        
                        setsuccessmessage('');
                        seterrormessage('');
                        setprogress(0);
                        setcount(0);

                        setfolder(null);
                        setitem(props.item);
                    } 

                    if(props.folder !== folder && props.folder !== null && props.folder !== undefined){
                        
                        //console.log('Actualizando carpeta');
                        //console.log(folder);
                        //console.log(props.folder);

                        setsending(false);
                        
                        setsuccessmessage('');
                        seterrormessage('');
                        setprogress(0);
                        setcount(0);

                        setfolder(props.folder);
                        setitem(null);
                    } 

                    if(props.items && props.items !== null && props.items !== undefined){
                        setsending(false);
                        
                        setsuccessmessage('');
                        seterrormessage('');

                        setprogress(0);
                        setcount(0);

                        setitems(props.items);

                        setitem(null);
                        setfolder(null);
                    }
                }
            }
        }else{
            setshowModal(props.show);
            setitem(null);
            setfolder(null);

            if(props.resetItems){
                // props.resetItems();
            }
        }
    });

    const moveTo = (e) => {
        let idToCopy = 0;

        let idFolder = 0;
        let typecopy = "D";

        if(typeCopy === "1"){
            if(navigationFolders.length === 0){
                idToCopy = 0
            }else{
                let newNavigationFolders = navigationFolders;
                idToCopy = newNavigationFolders[newNavigationFolders.length - 1];
            }

            typecopy = "D";
        }else if(typeCopy === "2"){
            if(categorySelected !== null){
                idToCopy = categorySelected.value;
                typecopy = "C";
            }
        }

        console.log(idToCopy);

        let url = '/filemanager/copy/';
        let data = {
            to:         typecopy,
            to_id:      Number(idToCopy),
            folders:    [item.id],
            files:      []
        }

        /*
        if(item !== null){
            // url = `/itemmanager/my-drive/move-item/${props.item.id}/to/${idFolder}/`;
        }else if(folder !== null){
            // url = `/itemmanager/my-drive/move-folder/${folder.id}/to/${idFolder}/`;
        }
        */
                
        //console.log(idFolder);

        if(typeCopy === "2" && categorySelected === null){
            setsuccessmessage('');
            seterrormessage('Debe seleccionar una categoría');  
            setsending(false);
        }else{
            setsuccessmessage('');
            seterrormessage('');  
            setsending(true);
            seterrors({});

            axios({
                url: url,
                method: 'post',
                data
            }).then((res) => {

                console.log(res.data);
                setsending(false);

                setsuccessmessage('Carpeta copiado exitosamente');

                //refresh
                props.refresh();

                setTimeout(() => {
                    props.close();

                    setsuccessmessage('');
                    seterrormessage(''); 

                    setitem(null);
                    setfolder(null);

                    setnewThree(null);
                    setthree(null);

                    setloading(true);
                    setsearch(true);

                    setnavigationFolders([]);

                    seterrors({});
                    setsending(false);

                    setcategorySelected(null);
                    

                }, 800);

            }).catch((err) => {
                console.error(err);

                
                    if(err){
                        setsending(false);
                        if(err.response){
                            let errors = err.response.data;

                            if(errors){
                                seterrors(errors);
                            }

                            if(errors.hasOwnProperty('non_field_errors')){
                                seterrormessage(err.response.data.non_field_errors);
                            }

                            if(errors.hasOwnProperty('detail')){
                                seterrormessage(err.response.data.detail);
                            }
                        }
                    }
                
            });
        }
    }

    const backToDirectory = () => {
        let newNavigation = navigationFolders;
        newNavigation.pop();

        setnavigationFolders(newNavigation);
        updateThree();
    }

    const updateThree = () => {
        let newThree = [];
        let it = 0;

        it = navigationFolders.length;

        if(three !== null){
            if(navigationFolders.length === 0){
                newThree = three[0];
                setnewThree(newThree);
            }else if(navigationFolders.length > 0){
                let directorySelected = three[0];

                for (let j = 0; j < it; j++) {
                    let folderId = navigationFolders[j];
                    //console.log("Ingresando al directorio");
                    //console.log(folderId);

                    if(directorySelected.children){
                        let findDirectory = directorySelected.children.find(item => { 
                            if(item.id === folderId){
                                //console.log("pasando al directorio", item);
                                directorySelected = item;
                                return item.id;
                            } 

                            return undefined;
                        });

                        //console.log(findDirectory);
                    }
                }

                //console.log(findDirectory);
                //newThree = findDirectory;

                newThree = directorySelected;
                setnewThree(newThree);
            }
        }
    }

    const selectDirectory = (id) => {
        let newNavigation = navigationFolders;
        newNavigation.push(id);

        setnavigationFolders(newNavigation);
        updateThree();
    }

    const getData = () => {
        axios.get(urlthree)
        .then((res) => {

            setthree(res.data);
            setnewThree(res.data[0]);

            setloading(false);

            console.log(res.data);

        }).catch((err) => {
            console.error(err);
        });
    }

    return (
        <div>
            <Modal animation={false} show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <strong>Copiar carpeta</strong>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(successmessage !== '') &&
                        <div className="mb-3 alert alert-success">
                            <p className="mb-0 font-weight-bold">{successmessage}</p>
                        </div>
                    }
                
                    { (progress > 0) &&
                        <div className="py-3">
                            <ProgressBar variant="success" animated now={progress} />
                        </div>
                    }

                    {(sending) && 
                        <InlineLoader class="minimal" />
                    }

                    {(successmessage === '' && !sending) &&
                        <div>
                            {(loading) 
                                ?
                                    <InlineLoader />
                                :

                                <div>
                                    {items !== null &&
                                        <div className="alert shadow-sm mb-3 alert-info">
                                            <p className="mb-0">
                                                {items.items.length+items.folders.length} items seleccionado(s)
                                            </p>  
                                        </div>
                                    }

                                    {(item !== null || folder !== null) &&
                                        <div className="alert shadow-sm mb-3 alert-info">
                                            <p className="mb-0">
                                                {(item !== null) && <span>Nombre:</span>} 
                                                {(item !== null) && 
                                                <span className="font-weight-bold pl-1">
                                                    {item.name}
                                                </span>}
                                               
                                            </p>
                                        </div>
                                    }

                                    <Tabs
                                        id="copyitemDirectory"
                                        activeKey={typeCopy}
                                        onSelect={(val) => settypeCopy(val)}
                                        className="mb-3"
                                    >
                                        <Tab eventKey="1"    title="Copiar en Mi Unidad">

                                            {(
                                                (three !== null && item   !== null && successmessage === '') ||
                                                (three !== null && folder !== null && successmessage === '') ||
                                                (three !== null && items  !== null && successmessage === '')
                                            ) &&
                                                <div className="three list-group">

                                                    <h5 className="mb-3 py-3">
                                                        Copiar en:
                                                        {(navigationFolders.length > 0) &&
                                                            <button onClick={() => backToDirectory()} className="btn-unstyled ml-2 mr-2">
                                                                <i className="fa fa-angle-left"></i>
                                                            </button>
                                                        }
                                                        <strong className="text-primary font-weight-bold ml-2">
                                                            <i className="fa fa-folder mr-2"></i>{newThree.data.name}
                                                        </strong>
                                                    </h5>

                                                    {newThree.hasOwnProperty("children") && Array.isArray(newThree.children) 
                                                    ?
                                                        <>
                                                            {newThree.children.map((item, key) => {
                                                                return (
                                                                    <li 
                                                                        onClick={() => selectDirectory(item.id)}
                                                                        style={{cursor:"pointer"}} 
                                                                        className="list-group-item list-group-item-action" 
                                                                        key={key}
                                                                    >
                                                                        <i className="fa fa-folder mr-3"></i> 
                                                                        {item.data.name}
                                                                    </li>
                                                                )
                                                            })}
                                                        </>
                                                    :
                                                        <li className="list-group-item list-group-item-action text-center">
                                                            Sin directorios
                                                        </li>
                                                    }
                                                </div>
                                            }
                                            
                                        </Tab>
                                    </Tabs>

                                    {(errormessage !== '') &&
                                        <div className="mb-3 alert alert-danger mt-3">
                                            <p className="mb-0 font-weight-bold">{errormessage}</p>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                
                </Modal.Body>
                {(successmessage === '') && 
                    <Modal.Footer>
                            <div>
                                {(item !== null || folder !== null) &&
                                    <Button disabled={sending || (typeCopy === "2" && categorySelected === null)} onClick={(e) => moveTo(e)} variant="primary" type="button" >
                                        {(sending) 
                                        ? 
                                            <span>
                                                <i className="fa fa-spinner fa-spin mr-2"></i>Cargando
                                            </span> 
                                        : 
                                            <span>
                                                Crear copia
                                            </span>
                                        }
                                    </Button>
                                }

                                <Button variant="light" onClick={props.close}>
                                    <i className="fa fa-times mr-2"></i>Cancelar
                                </Button>
                            </div>
                    </Modal.Footer>
                }
            </Modal>
        </div>
    )
}

export default ModalCopyFolderInDirectory;
