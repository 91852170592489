import React from 'react'
import {Switch, Route, Link, Redirect} from 'react-router-dom'
import {useSelector} from 'react-redux'

//Layout
import NavHeader from '../../helpers/NavHeader';
import Header from './layout/Header';
import Sidebar from './layout/Sidebar';
import Footer from './layout/Footer';

//Dashboard components
import Profile from './pages/Profile';
import Unidad from './pages/Unidad';
import Tags from './pages/Tags';
import Home from './pages/Home';
import Favourities from './pages/Favourities';
import Category from './pages/Category';
import Logout from './pages/Logout';
import Tag from './pages/Tag';
import FilePreview from './pages/FilePreview';
import File from '../public/pages/shared/File';
import Folder from '../public/pages/shared/Folder';
import PublicCategory from '../public/pages/shared/Category';
import Compartido from './pages/Compartido';
import FolderCompartido from './pages/FolderCompartido';
import CrearTicket from './pages/CrearTicket';
import Tickets from './pages/Tickets';
import Ticket from './pages/Ticket';
import SymbolicLinks from './pages/SymbolicLinks';

function NotFound(){
    return (
      <div>
        <div className="dashboard-content-page-container page-not-found">
        <div className="container">
            <div className="row align-items-center min-vh-100 justify-content-center">
              <div className="col-10">
                <div className="not-found-text text-center">
                  <h1 className="font-weight-bold mb-2" style={{fontSize: '100px'}}>404</h1>
                  <h5 className="mt-3 mb-4">La página que buscas no ha sido encontrada</h5>
                  <Link to="/dashboard" className="btn btn-info">
                      Ir al dashboard <i className="fa fa-undo-alt"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
  
const DashboardRoute = ({Component, access = true,  onlyComponent = false, dataRoute = [], ...rest}) => {
    if(access){
      return (
        <Route
          {...rest}
          render={
              props =>
              <div 
                id="container-app"
                className="container-app"

                data-typography="poppins" 
                data-theme-version="light" 
                data-layout="vertical" 
                data-nav-headerbg="color_3" 
                data-headerbg="color_1" 
                data-sidebar-style="full" 
                data-sibebarbg="color_3" 
                data-sidebar-position="fixed" 
                data-header-position="fixed" 
                data-container="wide" 
                direction="ltr" 
                data-primary="color_1"

              >
                {(!onlyComponent) ? 
                  <div id="main-wrapper" className="show">
                    <NavHeader />
                    <Header />
                    <Sidebar />
                    <div className="content-body">
                      <div className="container-fluid">
                        <Component 
                          dataRoute={dataRoute}
                          {...props} 
                        />
                      </div>
                    </div>
                    <Footer />
                  </div>
                :
                  <Component 
                    dataRoute={dataRoute}
                    {...props} 
                  />
                }
              </div>

          }
        />
      )
    }else{
      return <Redirect 
              to={{
                pathname: '/dashboard',
                state: {message: "No tiene acceso a esta sección"} 
              }} 
            />
    }
}

function Dashboard() {

    const session = useSelector(state => state.session);
    const userPermissions = session.userData.permissions;

    return (
        <Switch>

            {/* Home */}
            <DashboardRoute exact path="/dashboard"                           Component={Home} />
            <DashboardRoute exact path="/perfil"                              Component={Profile} />

            <DashboardRoute exact path="/favoritos"                           access={userPermissions.has_favorites_access}         Component={Favourities} />
            
            <DashboardRoute exact path="/accesos-directos"                    access={userPermissions.has_symbolic_links_access}   Component={SymbolicLinks} />

            <DashboardRoute exact path="/unidad"                              access={userPermissions.has_drive_access}         Component={Unidad} />
            <DashboardRoute exact path="/unidad/folder/:id"                   access={userPermissions.has_drive_access}         Component={Unidad} />

            <DashboardRoute exact path="/tickets"                                   access={userPermissions.has_tickets_access}         Component={Tickets} />
            <DashboardRoute exact path="/tickets/view/:id"                          access={userPermissions.has_tickets_access}         Component={Ticket} />
            <DashboardRoute exact path="/crear-ticket"                              access={userPermissions.has_tickets_access}         Component={CrearTicket} />

            <DashboardRoute exact path="/compartido-conmigo"                                    access={userPermissions.has_shared_with_me_access} Component={Compartido} />
            <DashboardRoute exact path="/compartido-conmigo/folder/:id"                         access={userPermissions.has_shared_with_me_access} Component={FolderCompartido} />
            <DashboardRoute exact path="/compartido-conmigo/folder/:id/descendant/:subfolder"   access={userPermissions.has_shared_with_me_access} Component={FolderCompartido} />

            <DashboardRoute exact path="/etiquetas"                  access={userPermissions.has_tags_access}         Component={Tags} />
            <DashboardRoute exact path="/etiqueta/:id"               access={userPermissions.has_tags_access}         Component={Tag} />

            <DashboardRoute exact path="/categoria/:id"                       Component={Category} />
            <DashboardRoute exact path="/file/preview/:id"                    Component={FilePreview} />

            <DashboardRoute onlyComponent exact path="/logout"                Component={Logout} />

            <DashboardRoute exact path="/shared-files/:uuid"                  Component={File} />
            <DashboardRoute exact path="/shared-folder/:uuid"                 Component={Folder} />
            <DashboardRoute exact path="/shared-folder/:uuid/subfolder/:id"   Component={Folder} dataRoute={{type: "subfolder"}} />
            
            <DashboardRoute exact path="/shared-category/:uuid"                 Component={PublicCategory} />
            <DashboardRoute exact path="/shared-category/:uuid/subcategory/:id" Component={PublicCategory}  dataRoute={{type: "subcategory"}} />

            <Redirect from='/login' to="/dashboard" />
            <Redirect from='/'      to="/dashboard" />
            
            {/*404*/}
            <Route component={NotFound} />
    
        </Switch>
    )
}

export default Dashboard;
