import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import { reset_dashboard } from '../../../../store/dashboard/actions';
import { handleLogout } from '../../../../store/session/actions';
import Loader from '../../../helpers/Loader'

function Logout() {
    const [loading, setloading] = useState(true);
    const dispatch = useDispatch();

    const handle_logout = async () => {
        await dispatch(handleLogout());
        await dispatch(reset_dashboard());
        setloading(false);
    }

    useEffect(() => {
        if(loading){
            setTimeout(() => {
                handle_logout();
            }, 200);
        }
    }, []);

    return (
        <div>
            <Loader />
        </div>
    )
}

export default Logout
