import React, {useState, useEffect} from 'react'
import {
    Modal,
    Button,
    ProgressBar
} from 'react-bootstrap'
import axios from 'axios'
import { onlyTextAndNumbersAndDot } from '../utils/inputs';

function ModalCreateFolder(props) {

    const [showModal,       setshowModal]  = useState(false);

    const [sending, setsending]                         = useState(false);
    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');
    const [progress, setprogress]                       = useState(0);
    const [count,    setcount]                          = useState(0);

    const [name, setname]                               = useState('');
    const [errors,   seterrors]                         = useState({});

    const validate = () => {
        return true;
    }

    useEffect(() => {
        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setsuccessmessage('');
                seterrormessage('');
                setname('');
                setprogress(0);
            }
        }else{
            setshowModal(props.show);
        }
    });

    const createFolder = (e) => {
        let url = '/filemanager/my-drive/folders/';
        if(props.folder){
            url = '/filemanager/my-drive/folders/'+props.folder+'/';
        }

        if(props.isShare){
            if(props.parent){
                url = '/filemanager/shared-with-me/folders/'+props.parent+'/';
            }

            if(props.subfolder){
                url = '/filemanager/shared-with-me/folders/'+props.parent+'/descendant/'+props.subfolder+'/';
            }
        }

        let isvalid = validate();
            seterrors({});
            setsending(true);
            setsuccessmessage('');
            seterrormessage('');

            if(isvalid){
            axios({
                url: url,
                method: 'post',
                data: {
                    name
                }
            }).then((res) => {
                console.log(res.datadata);
                setsending(false);
                setname('');
                if(props.folder){
                    setsuccessmessage('Subcarpeta creada correctamente');
                }else{
                    setsuccessmessage('Carpeta creada correctamente');
                }

                if(props.refresh){
                    props.refresh();
                }

                setTimeout(() => {
                    props.close();
                }, 1000);

            }).catch((err) => {
                console.error(err);
                setsending(false);
                if(err.response.data){
                    seterrors(err.response.data);
                }
                seterrormessage('Ha ocurrido algo al intentar crear la carpeta intentelo nuevamente');
            });
        }
    }

    return (
        <div>
            <Modal animation={false} show={props.show} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <strong>Crear {props.folder ? 'subcarpeta' : 'carpeta'}</strong>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(successmessage !== '') &&
                        <div className="mb-3 alert alert-success">
                            <p className="mb-0 font-weight-bold">{successmessage}</p>
                        </div>
                    }
                    {(errormessage !== '') &&
                        <div className="mb-3 alert alert-danger">
                            <p className="mb-0 font-weight-bold">{errormessage}</p>
                        </div>
                    }
                    { (progress > 0) &&
                        <div className="py-3">
                            <ProgressBar variant="success" animated now={progress} />
                        </div>
                    }

                    <div className="folder-creator">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="foldername">Nombre de la carpeta</label>
                                    <input 
                                        value={name}
                                        onKeyPress={(e) => onlyTextAndNumbersAndDot(e)}
                                        onChange={(e) => setname(e.target.value)}
                                        placeholder="Nombre de la carpeta"
                                        type="text" 
                                        className="form-control"
                                    />
                                </div>
                                {errors.hasOwnProperty('name') && Array.isArray(errors.name) &&
                                    <div className="help-block text-danger font-weight-bold">
                                        <p className="mb-0 py-2">
                                            <i className="fa fa-exclamation-triangle mr-2"></i>
                                            {errors.name[0]}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={sending} onClick={(e) => createFolder(e)} variant="primary" type="button" >
                        {(sending) ? <span><i className="fa fa-spinner fa-spin mr-2"></i>Cargando</span> : <span><i className="fa fa-ckeck mr-2"></i>Crear carpeta</span>}
                    </Button>
                    <Button variant="light" onClick={props.close}>
                        <i className="fa fa-times mr-2"></i>Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalCreateFolder
