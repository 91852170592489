import React, {Fragment, useState, useEffect} from 'react'
import {Dropdown} from "react-bootstrap"
import BtnAddToFavourities from './BtnAddToFavourities';
import BtnAddToSymbolicLink from './BtnAddToSymbolicLink';
import axios from "axios"

function DataGroupButtons({id, type, item, page, permission = null, align = "center", userPermissions, showModal, permissions = {}, updateFavBtn = function(id){ console.log(id) }, updateSymbolicBtn = function(id){ console.log(id) } }) {

    //console.log(item.id, (item.is_favorite || permissions.can_remove_of_favorites));
    let favValue = item.is_favorite || permissions.can_remove_of_favorites;
    let url = "/filemanager/favorites/"+item.id+"/";

    const [loading, setloading]          = useState(false);
    const [show,  setshow]               = useState(false);
    const idItem                         = `dropdown-${id}`;


    const [direction, setdirection]      = useState("down");
    const [isFav, setisFav]              = useState(item.is_favorite || permissions.can_remove_of_favorites);
    
    const [blockFlip, setblockFlip]      = useState(false);
    
    //Category actions ------------------------------------------

    const RenameCategoryNow = (folder) => {
        showModal(folder, "rename", "category");
    }

    /*
    const moveCategoryNow = (folder) => {
        showModal(folder, "move", "category");
    }
    */

    const shareCategoryNow = (folder) => {
        showModal(folder, "share", "category");
    }

    const deleteCategoryNow = (folder) => {
        showModal(folder, "delete", "category");
    }

    //Folder actions ------------------------------------------

    const RenameFolderNow = (folder) => {
        showModal(folder, "rename", "folder");
    }

    const CopyFolderNow = (folder) => {
        showModal(folder, "copy", "folder");
    }

    const CopyFolderInDirectory = (folder) => {
        showModal(folder, "copyInDirectory", "folder");
    }

    const moveFolderNow = (folder) => {
        showModal(folder, "move", "folder");
    }

    const shareFolderNow = (folder) => {
        showModal(folder, "share", "folder");
    }

    const deleteFolderNow = (folder) => {
        showModal(folder, "delete", "folder");
    }

    //File actions-----------------------------------------------

    const RenameFileNow = (file) => {
        showModal(file, "rename", "file");
    }

    const CopyFileNow = (file) => {
        showModal(file, "copy", "file");
    }

    const CopyFileInDirectory = (file) => {
        showModal(file, "copyInDirectory", "file");
    }

    const MoveFileNow = (file) => {
        showModal(file, "move", "file");
    }

    const ShareFileNow = (file) => {
        showModal(file, "share", "file");
    }

    const DeleteFileNow = (file) => {
        showModal(file, "delete", "file");
    }

    const DownlodFileNow = (file) => {
        showModal(file, "download", "file");
    }

    const ShowFileNow = (file) => {
        showModal(file, "show", "file");
    }

    const EditStatus = (file) => {
        showModal(file, "editStatus", "file");
    }

    const EditCategory = (file) => {
        showModal(file, "editCategory", "file");
    }

    const hideMenu = () => {
        setTimeout(() => {
            let table = document.querySelector(`#dropdown-${id}`).closest(".table-responsive");
            if(table){
                //console.log(table);

                if(table.classList.contains("isDropdownOpen")){
                    table.classList.remove("isDropdownOpen");
                }
                setshow(false);
                setdirection("down");
                setblockFlip(false);
            }
        }, 5);
    }

    const toggleShow = async () => {
        let dataShow = !show;
        await setshow(dataShow);

        if(dataShow){
            let dropdown       = document.querySelector(`#dropdown-${id}`);
            let table          = dropdown.closest(".table");
            let tablResponsive = table.closest(".table-responsive");

            var recttrsp       = tablResponsive.getBoundingClientRect();

            //let thisDropdown          = document.querySelector(`[aria-labelledby]="${idItem}"`);
            //let Dropdownexample       = document.querySelector(`.dropdown-menu[aria-labelledby]`);
            //let thisDropdown          = document.querySelector(`.dropdown-menu[aria-labelledby]="${id}"`);

            //console.log(idItem);
            //console.log(Dropdownexample);
            //console.log(thisDropdown);

            if(tablResponsive){
                
                //console.log(table);
                if(!tablResponsive.classList.contains("isDropdownOpen")){
                    tablResponsive.classList.add("isDropdownOpen");
                }   

                setTimeout(() => {
                    let thisDropdown = table.querySelector(".dropdown-menu.show");

                    let wHeight      = window.innerHeight;

                    var rect         = thisDropdown.getBoundingClientRect();
                    let rectYDim     = rect.y + rect.height;
                    let yDiff        = wHeight - rectYDim;

                    console.log(yDiff);

                    if(yDiff - 0){

                        yDiff = yDiff*-1;
                        let marginBtn = yDiff;

                        tablResponsive.style.paddingBottom = `${yDiff + 10}px`;
                    }

                    // console.log(rectt.height - rectt.top);
                    // var tableRect    = table.getBoundingClientRect();
                    //console.log(tableRect);

                    // console.log(thisDropdown);
                    // console.log(rect.bottom);
                    // console.log(window.innerHeight);

                    // if(rect.top < window.innerHeight){
                        // setdirection("down");
                        // setblockFlip(true);
                    // }
                }, 20)
          
            }
        }else{
            
            let dropdown       = document.querySelector(`#dropdown-${id}`);
            let table          = dropdown.closest(".table");
            let tablResponsive = table.closest(".table-responsive");

            //var rect            = dropdown.getBoundingClientRect().bottom;
            //console.log(rect);

            if(tablResponsive){
                //console.log(table);
                setdirection("down");
                setblockFlip(false);

                tablResponsive.removeAttribute("style");

                setTimeout(() => {
                    let thisDropdown = tablResponsive.querySelector(".dropdown-menu.show");
                    if(tablResponsive.classList.contains("isDropdownOpen")){
                        console.log(thisDropdown);

                        if(!thisDropdown){
                            tablResponsive.classList.remove("isDropdownOpen");
                        }
                    }
                }, 5);
            }
        }
    }

    // console.log(permissions);

    return (
        <div className={`w-100 d-flex align-items-center justify-content-${align}`}>
            <Dropdown 
                show={show} 
                alignRight={true}
                onMouseLeave={() => show ? toggleShow() : ""}
                drop={direction}
                flip={!blockFlip.toString() || false}
            >
                <Dropdown.Toggle 
                    onClick={() => toggleShow()} 
                    variant="primary" 
                    id={idItem}
                >
                    <span className='font-weight-normal pr-2'>
                        <i className="fa fa-cog"></i>
                    </span>
                </Dropdown.Toggle>

                {type === "category" &&
                    <Dropdown.Menu renderOnMount={true}>

                        {(permissions.can_rename) &&
                            <Dropdown.Item onClick={() => RenameCategoryNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fas fa-file-signature mr-2"></i>Renombrar
                                </span>
                            </Dropdown.Item>
                        }

                        {
                            /*
                        (permissions.can_move) &&
                            <Dropdown.Item onClick={(e) => moveFolderNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="far fa-hand-rock mr-2"></i>Mover
                                </span>
                            </Dropdown.Item>
                            */
                        }

                        {(permissions.can_share) &&
                            <Dropdown.Item onClick={(e) => shareCategoryNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-share-alt mr-2" />Compartir
                                </span>
                            </Dropdown.Item>
                        }

                        {(permissions.can_delete) &&
                            <Dropdown.Item onClick={() => deleteCategoryNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-trash mr-2" />Eliminar
                                </span>
                            </Dropdown.Item>
                        }
                        
                    </Dropdown.Menu>
                }

                {type === "folder" &&
                    <Dropdown.Menu renderOnMount={true}>

                        {((permission === "E" || permission === null) || (typeof permission === "object" && permission !== null && permission.can_rename)) &&
                            <Dropdown.Item onClick={() => RenameFolderNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fas fa-file-signature mr-2"></i>Renombrar
                                </span>
                            </Dropdown.Item>
                        }

                        <Dropdown.Item 
                            onClick={() => CopyFolderNow(item)}
                        >
                            <span className='font-weight-normal'>
                                <i className="fas fa-copy mr-2"></i>Crear una copia
                            </span>
                        </Dropdown.Item>

                        <Dropdown.Item 
                            onClick={() => CopyFolderInDirectory(item)}
                        >
                            <span className='font-weight-normal'>
                                <i className="far fa-copy mr-2"></i>copiar en ...
                            </span>
                        </Dropdown.Item>

                        {((permission === null) || (typeof permission === "object" && permission !== null && permission.can_move)) &&
                            <Dropdown.Item onClick={(e) => moveFolderNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="far fa-hand-rock mr-2"></i>mover a ...
                                </span>
                            </Dropdown.Item>
                        }

                        {((permission === null) || (typeof permission === "object" && permission !== null && permission.can_share)) &&
                            <Dropdown.Item onClick={(e) => shareFolderNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-share-alt mr-2" />Compartir
                                </span>
                            </Dropdown.Item>
                        }

                        {((permission === "E" || permission === null) || (typeof permission === "object" && permission !== null && permission.can_delete)) &&
                            <Dropdown.Item onClick={() => deleteFolderNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-trash mr-2" />Eliminar
                                </span>
                            </Dropdown.Item>
                        }
                        
                    </Dropdown.Menu>
                }

                {type === "file" &&
                    <Dropdown.Menu renderOnMount={true}>

                        {((permissions !== null && permissions.can_rename) || (permissions === "E")) &&
                            <Dropdown.Item onClick={() => RenameFileNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fas fa-file-signature mr-2"></i>Renombrar
                                </span>
                            </Dropdown.Item>
                        }

                        {permissions.can_copy &&
                            <Dropdown.Item onClick={() => CopyFileNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fas fa-copy mr-2"></i>Crear una copia
                                </span>
                            </Dropdown.Item>
                        }

                        {permissions.can_copy &&
                            <Dropdown.Item onClick={() => CopyFileInDirectory(item)}>
                                <span className='font-weight-normal'>
                                    <i className="far fa-copy mr-2"></i>copiar en ...
                                </span>
                            </Dropdown.Item>
                        }

                        {permissions.can_move &&
                            <Dropdown.Item onClick={() => MoveFileNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="far fa-hand-rock mr-2"></i>mover a ...
                                </span>
                            </Dropdown.Item>
                        }

                        {permissions.can_share &&
                            <Dropdown.Item onClick={() => ShareFileNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-share-alt mr-2" />Compartir
                                </span>
                            </Dropdown.Item>
                        }

                        {permissions.can_delete &&
                            <Dropdown.Item onClick={() => DeleteFileNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-trash mr-2" />Eliminar
                                </span>
                            </Dropdown.Item>
                        }

                        {(permissions.can_preview || (permissions === "E" || permissions === "R")) &&
                            <Dropdown.Item onClick={() => ShowFileNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-eye mr-2" />Previsualizar
                                </span>
                            </Dropdown.Item>
                        }

                        {(permissions.can_download || (permissions === "E" || permissions === "R")) &&
                            <Dropdown.Item onClick={() => DownlodFileNow(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-download mr-2" />Descargar
                                </span>
                            </Dropdown.Item>
                        }

                        {/* 
                            <Dropdown.Divider />
                        */}

                        
                        {permissions.can_change_status &&
                            <Dropdown.Item onClick={() => EditStatus(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-cog mr-2" />Cambiar estado
                                </span>
                            </Dropdown.Item>
                        }

                        {permissions.can_change_category &&
                            <Dropdown.Item onClick={() => EditCategory(item)}>
                                <span className='font-weight-normal'>
                                    <i className="fa fa-cog mr-2" />Cambiar categoría
                                </span>
                            </Dropdown.Item>
                        }
                            
                        
                        {/*(page === "unidad" || page === "simbolic-links") &&
                            <BtnAddToSymbolicLink updateOnChage={(iditem) => updateSymb(iditem)}  type="dropdown" status={item.is_symbolic_link || page === "simbolic-links"} itemId={item.id} />
                        */}

                        {(page === "unidad" || page === "simbolic-links") &&
                            <BtnAddToSymbolicLink updateOnChage={(iditem) => updateSymbolicBtn(iditem)}  type="dropdown" status={item.is_symbolic_link || page === "simbolic-links"} itemId={item.id} />
                        }

                        {(page === "unidad" || permissions.can_remove_of_favorites || permissions.can_add_to_favorites) &&
                            <BtnAddToFavourities updateOnChage={(iditem) => updateFavBtn(iditem)}  type="dropdown" status={favValue} itemId={item.id} />
                        }
                    </Dropdown.Menu>
                }
            </Dropdown>
        </div>
    )
}

export default DataGroupButtons