import {LOGIN, LOGOUT, SET_PHOTO, SET_USER_DATA, CHANGE_USER_DATA, SET_SETTINGS} from '../types'

const initialState = {
    auth: false,
    userData : null,
    settings: null
}

const sessionReducer = (state=initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return{
               ...state,
               auth: true,
               userData: action.payload
            };
        
        case LOGOUT:
            return{
               ...state,
               auth: false,
               userData : null,
               settings: null
            };

        case SET_USER_DATA:
            return{
                ...state,
                userData: action.payload
            };

        case CHANGE_USER_DATA:
            let { name,first_name,last_name,email,address,phone } = action.payload;

            return{
                ...state,
                userData: {
                    ...state.userData,
                    name,
                    first_name, 
                    last_name,
                    email,
                    address,
                    phone
                }
            };

        case SET_PHOTO:

            let newUserData = state.userData;
            newUserData.photo = action.payload;
            return{
                ...state,
                userData: newUserData
            };
        
        case SET_SETTINGS:
            return{
                ...state,
                settings: action.payload
            };
    
        default: return state;
    }
}

export default sessionReducer;