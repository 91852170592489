import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link} from "react-router-dom"
import {Modal,Button, Nav} from 'react-bootstrap'
import axios from 'axios'
import InlineLoader from './InlineLoader';
import { copyToClipboard } from '../utils/urls';
import SelectRole from './SelectRole';
import SelectPermissions from './SelectPermissions';
import Stepthree from './share/Stepthree'
import StepTwo from './share/StepTwo'

function ModalShareFile(props) {

    const session = useSelector(state => state.session);
    const userPermissions = session.userData.permissions;

    let activeDefaultStep =  0;

    let onlyenlace = props.onlyenlace ? true : false;

    if(userPermissions.can_share_files_by_link){
        activeDefaultStep = 1;
    }else if(userPermissions.can_share_files_to_users){
        activeDefaultStep = 2;
    }else if(userPermissions.can_share_files_to_groups){
        activeDefaultStep = 3;
    }

    const [loading,         setloading]     = useState(true);
    const [search,          setsearch]      = useState(true);

    const [showModal,       setshowModal]   = useState(false);
    const [step,            setstep]        = useState(activeDefaultStep);
    const [message,         setmessage]     = useState("");

    const [file,            setfile]        = useState(null);
    const [uuid,            setuuid]        = useState('');

    //users
    const [usersList,       setusersList]   = useState(null);
    const [sharing,         setsharing]     = useState(false);
    const [isShared,        setIsShared]    = useState(false);

    //filedata
    const [name, setname]                   = useState((props.file) ? props.file.name : '');
    const [format, setformat]               = useState('');

    let isPublic = props.ispublic ? true : false;

    let urlpublicpathtoFiles = "/shared-files/";

    useEffect(() => {
        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
            }

            if(props.file !== file && props.file !== null && props.file !== undefined){
                console.log(props.file);

                setfile(props.file);
                setIsShared(props.file.is_shared);
    
                let filename = props.file.name.split('.');
                let formattext = filename[filename.length - 1];
                setformat(formattext);
                let filenametext = props.file.name.substring(0, props.file.name.length - (formattext.length + 1));
                setname(filenametext);

                if(loading){
                    if(search){
                        setsearch(false);
                        const getDataShared = () => {
                            let urlGet = "/filemanager/get-uuid-file/"+props.file.id;
                            let urlUsers = "/accounts/list-all-active-users/";

                            axios.get(urlUsers).then((res) => {

                                console.log(res.data);
                                setusersList(res.data);

                                if(userPermissions.can_share_files_by_link){
                                    axios.get(urlGet).then((res) => {
                                        console.log(res.data);
                                        setuuid(res.data.uuid);
                                        setloading(false);
                                    }).catch((err) => {
                                        console.error(err);
                                    });
                                }else{
                                    setloading(false);
                                }
                            }).catch((err) => {
                                console.error(err);
                            });
                        }
        
                        getDataShared();
                    }
                }
            } 

        }else{
            setloading(true);
            setsearch(true);
            setstep(activeDefaultStep);
            setname("");
            setformat("");
            setfile(null);
            setshowModal(props.show);
        }
    });

    const copy = (e, text) => {
        e.preventDefault();
        copyToClipboard(text);
        setmessage("Url copiada!");
        setTimeout(() => {
            setmessage("");
        }, 400);
    }

    const share = () => {
        setIsShared(true);
        props.refresh();
    }

    return (
        <div>
            <Modal size="lg" animation={false} show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <strong>Compartir {props.file ? 'archivo' : ''} {props.folder ? 'carpeta' : ''}</strong>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {loading 
                        ? 
                        <InlineLoader />
                        :
                        <div className="content-steps-share">
                            <div className="content-tabs mb-4">
                                <Nav fill  variant="tabs" defaultActiveKey={step}>
                                    {userPermissions.can_share_files_by_link &&
                                    <Nav.Item>
                                        <Nav.Link onClick={() => setstep(1)} eventKey={1}>
                                            Enlace
                                        </Nav.Link>
                                    </Nav.Item>
                                    }
                                    {userPermissions.can_share_files_to_users && !isPublic && !onlyenlace &&
                                    <Nav.Item>
                                        <Nav.Link onClick={() => setstep(2)} eventKey={2}>
                                            Usuarios
                                        </Nav.Link>
                                    </Nav.Item>
                                    }
                                    {userPermissions.can_share_files_to_groups && !isPublic && !onlyenlace &&
                                    <Nav.Item>
                                        <Nav.Link onClick={() => setstep(3)} eventKey={3}>
                                            Roles
                                        </Nav.Link>
                                    </Nav.Item>
                                    }
                                </Nav>
                            </div>
                            {step === 1 && userPermissions.can_share_files_by_link &&
                                <div className="step-1">
                                    <div className="form-group">
                                        <h6 className="font-weight-bold mb-3">
                                            Archivo: <span className="font-weight-normal">{file.name}</span>
                                        </h6>
                                        <div className="input-group">
                                            <input 
                                                type="text" 
                                                readOnly
                                                disabled
                                                className="form-control" 
                                                value={window.location.origin+urlpublicpathtoFiles+uuid}
                                                placeholder="Url publica" 
                                            />
                                            <div className="input-group-append">
                                                <button 
                                                    onClick={(e) => copy(e,window.location.origin+urlpublicpathtoFiles+uuid)}
                                                    className="btn btn-primary" 
                                                    type="button"
                                                >
                                                    {(message !== "") 
                                                        ?
                                                            <i className="far fa-check-circle"></i>
                                                        :
                                                            <i className="far fa-copy"></i>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Link 
                                            className="btn font-weight-bold btn-primary" 
                                            to={urlpublicpathtoFiles+uuid}
                                        >
                                            Abrir enlace 
                                            <i className="ml-2 fa fa-external-link-alt"></i>
                                        </Link>
                                    </div>
                                    <div className="text-right">
                                        <Button variant="light" onClick={props.close}>
                                            <i className="fa fa-times mr-2"></i>Cerrar
                                        </Button>
                                    </div>
                                </div>
                            }

                            {step === 2 && userPermissions.can_share_files_to_users && !isPublic &&
                                <div className="step-2">
                                    <StepTwo 
                                        type="file"
                                        isShared={isShared}
                                        share={() => share()}
                                        id={props.file.id}
                                        usersList={usersList !== null ? usersList : []}
                                        btnCancel={
                                            <Button variant="light" onClick={props.close}>
                                                <i className="fa fa-times mr-2"></i>Cerrar
                                            </Button>
                                        } 
                                    />
                                </div>
                            }

                            {step === 3 && userPermissions.can_share_files_to_groups && !isPublic &&
                                <div>
                                    <Stepthree 
                                        type="file"
                                        isShared={isShared}
                                        share={() => share()}
                                        id={props.file.id}
                                        usersList={usersList !== null ? usersList : []}
                                        btnCancel={
                                            <Button variant="light" onClick={props.close}>
                                                <i className="fa fa-times mr-2"></i>Cerrar
                                            </Button>
                                        } 
                                    />
                            </div>
                            }
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalShareFile
