import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Tab,Row,Nav,Col} from 'react-bootstrap'
import profileImg from '../../../../static/images/profile/profile.png'
import AccountSettings from '../../../helpers/AccountSettings';
import ChangePassword from '../../../helpers/ChangePassword';
import ModalPhoto from '../../../helpers/ModalPhoto';

function Profile() {

    const [showModalPhoto, setshowModalPhoto]   = useState(false);
    const handleCloseModalPhoto                 = () => setshowModalPhoto(false);
    const handleShowModalPhoto                  = () => setshowModalPhoto(true);

    const data = useSelector(state => state.session.userData);
    let photo = data.photo;
    console.log(data);

    return (
        <div>
        <div className="page-titles">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="">
                Dashboard
              </a>
            </li>
            <li className="breadcrumb-item active">
              <a href="">
                Perfil
              </a>
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="profile card card-body px-3 pt-3 pb-0">
              <div className="profile-head">
                <div className="photo-content">
                  <div className="cover-photo" />
                </div>
                <div className="profile-info">
                  <div className="profile-photo">
                    <img src={(photo === null) ? profileImg : photo} className="img-fluid rounded-circle" alt="" />
                  </div>
                  <div className="profile-details">
                    <div className="profile-name px-3 pt-2">
                      <h4 className="text-primary mb-0">{data.name}</h4>
                      <p>{data.username}</p>
                    </div>
                    <div className="profile-email px-3 pt-2">
                      <h4 className="text-muted mb-0">{data.email}</h4>
                      <p>Email</p>
                    </div>
                  </div>
                </div>
                <button onClick={() => handleShowModalPhoto()} className="btn btn-primary mx-2 mb-3 btn-sm">
                  <i className="fa fa-camera mr-2"></i>Editar foto 
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="profile-tab">

                <Tab.Container id="profile-tab" className="custom-tab-1" defaultActiveKey="about">
                  <Row>
                    <Col sm={12}>
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="about">
                            Acerca de mi
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="settings">
                            Configuración
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="password">
                            Cambiar contraseña
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="about">
                        <div className="profile-personal-info mt-4">
                          <h4 className="text-primary mb-4">Información Personal</h4>
                          <div className="row mb-2">
                            <div className="col-md-3">
                              <h5 className="f-w-500">Nombres <span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-md-9"><span>{data.name}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-3">
                              <h5 className="f-w-500">Email <span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-md-9"><span>{data.email}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-3">
                              <h5 className="f-w-500">Teléfono <span className="pull-right">:</span></h5>
                            </div>
                            <div className="col-md-9"><span>{data.phone}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-3">
                              <h5 className="f-w-500">Dirección <span className="pull-right">:</span></h5>
                            </div>
                            <div className="col-md-9"><span>{data.address}</span>
                            </div>
                          </div>
                        </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="settings">
                        <div className="pt-3">
                          <AccountSettings data={data} />
                        </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="password">
                        <div className="pt-3">
                          <ChangePassword data={data} />
                        </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <ModalPhoto show={showModalPhoto} close={handleCloseModalPhoto} />
      </div>
    )
}

export default Profile
