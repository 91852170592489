import React, {useState, useEffect} from 'react'
import {
    Modal,
    Button,
    ProgressBar
} from 'react-bootstrap'
import axios from 'axios'
import { onlyTextAndNumbers } from '../utils/inputs';

function ModalRenameFile(props) {

    const [showModal,       setshowModal]  = useState(false);

    const [sending, setsending]                         = useState(false);
    const [successmessage, setsuccessmessage]           = useState('');
    const [errormessage, seterrormessage]               = useState('');
    const [progress, setprogress]                       = useState(0);
    const [count,    setcount]                          = useState(0);
    const [file,   setfile]                             = useState(null);

    const [errors,   seterrors]                         = useState({});

    const [name, setname]                               = useState((props.file) ? props.file.name : '');
    const [format, setformat]                           = useState('');

    const validate = () => {
        let errors = {};
        let countErrors = 0;
        if(props.file && props.file !== null){
            if(name === props.file.filename){
                seterrormessage('Debe cambiar el nombre para actualizar');
                countErrors++;
            }
        }

        if(countErrors > 0){
            return false;
        }

        return true;
    }

    useEffect(() => {
        //console.log(file);
        //console.log(props.file);

        if(props.show === true){
            if(!showModal){
                setshowModal(props.show);
                setsuccessmessage('');
                seterrormessage('');
                setprogress(0);
                setcount(0);
            }

            if(props.file !== file && props.file !== null && props.file !== undefined){
                console.log('Actualizando archivo');
                setsending(false);
                
                setsuccessmessage('');
                seterrormessage('');
                setprogress(0);
                setcount(0);
    
                setfile(props.file);
    
                let filename = props.file.name.split('.');
                let formattext = filename[filename.length - 1];
                setformat(formattext);
    
                //console.log(formattext.length);
                //console.log(props.file.name.length);
                //console.log(props.file.name.length - (formattext.length + 1));
    
                let filenametext = props.file.name.substring(0, props.file.name.length - (formattext.length + 1));
                setname(filenametext);
            } 

        }else{
            setfile(null);
            setshowModal(props.show);
        }
    });

    const createFolder = (e) => {
        let url = '';

        if(props.file){
            if(props.isShare){
                if(props.parent){
                    if(props.subfolder){
                        url = '/filemanager/shared-with-me/folders/'+props.parent+'/descendant/'+props.subfolder+'/files/'+props.file.id+'/';
                    }else{
                        url = '/filemanager/shared-with-me/folders/'+props.parent+'/files/'+props.file.id+'/';
                    }
                }else{
                    url = '/filemanager/shared-with-me/files/'+props.file.id+'/';
                }
            }else{
                url = '/filemanager/my-drive/files/'+props.file.id+'/';
            }

            if(props.ispublic){
                url = `/filemanager/public-file/${props.file.id}/`;
            }

            if(props.file.hasOwnProperty("is_symbolic_link") && props.file.is_symbolic_link){
                url = '/filemanager/my-drive/files/'+props.file.id+'/';
            }
        }

        let isvalid = validate();

            if(isvalid){
                
            seterrors({});
            setsending(true);
            setsuccessmessage('');
            seterrormessage('');  

            axios({
                url: url,
                method: 'put',
                data: {
                    name: name
                }
            }).then((res) => {
                console.log(res.datadata);
                setsending(false);
                setname('');
                if(props.file){
                    setsuccessmessage('archivo renombrado correctamente');
                }else{
                    setsuccessmessage('archivo renombrado correctamente');
                }

                //refresh
                props.refresh();
                setTimeout(() => {
                    props.close();
                }, 1000);
            }).catch((err) => {
                console.error(err);
                setsending(false);
                if(err.response.data){
                    seterrors(err.response.data);
                }
                if(err.response.data.hasOwnProperty('non_field_errors')){
                    seterrormessage(err.response.data.non_field_errors);
                }else if(err.response.data.hasOwnProperty('name')){
                    seterrormessage(err.response.data.name);
                }
            });
        }
    }

    return (
        <div>
            <Modal animation={false} show={showModal} onHide={props.close}>
                <Modal.Header closeButton>
                <Modal.Title>
                    <strong>Renombrar {props.file ? 'archivo' : 'archivo'}</strong>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(successmessage !== '') &&
                        <div className="mb-3 alert alert-success">
                            <p className="mb-0 font-weight-bold">{successmessage}</p>
                        </div>
                    }
                    {(errormessage !== '') &&
                        <div className="mb-3 alert alert-danger">
                            <p className="mb-0 font-weight-bold">{errormessage}</p>
                        </div>
                    }
                    { (progress > 0) &&
                        <div className="py-3">
                            <ProgressBar variant="success" animated now={progress} />
                        </div>
                    }

                    <div className="file-creator">
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="foldername">
                                    Nombre del archivo
                                </label>
                                <div className="input-group">
                                    <input 
                                        value={name}
                                        onKeyPress={(e) => onlyTextAndNumbers(e)}
                                        onChange={(e) => setname(e.target.value)}
                                        placeholder="Nombre del archivo"
                                        type="text" 
                                        className="form-control"
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            {format}
                                        </span>
                                    </div>
                                </div>
                                {errors.hasOwnProperty('name') && Array.isArray(errors.name) &&
                                    <div className="help-block d-none text-danger font-weight-bold">
                                        <p className="mb-0 py-2">
                                            <i className="fa fa-exclamation-triangle mr-2"></i>
                                            {errors.name[0]}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={sending} onClick={(e) => createFolder(e)} variant="primary" type="button" >
                        {(sending) ? <span><i className="fa fa-spinner fa-spin mr-2"></i>Cargando</span> : <span><i className="fa fa-ckeck mr-2"></i>Renombrar</span>}
                    </Button>
                    <Button variant="light" onClick={props.close}>
                        <i className="fa fa-times mr-2"></i>Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalRenameFile
